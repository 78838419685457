.sidebar-left {
  width: 60px;
  flex-shrink: 0;
  height: 100%;
  background-color: #1c1c2a;
  color: rgba(108, 116, 255, 0.9);
  padding: 10px;
  font-family: 'Share Tech Mono', monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.sidebar-spacer {
  flex: 1;
} 