.survivor-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 90%;
}

.survivor-score__thermometer {
  position: relative;
  width: 24px;
  height: calc(100% - 30px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 
    inset 0 0 15px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(255, 255, 255, 0.1);
}

.survivor-score__fill {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to top,
    rgba(255, 51, 102, 0.9),
    rgba(255, 153, 102, 0.9)
  );
  transition: height 1s ease-out;
  box-shadow: 0 0 20px rgba(255, 51, 102, 0.3);
}

.survivor-score__shine {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.2) 50%,
    transparent 100%
  );
}

.survivor-score__marks {
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.survivor-score__mark {
  position: absolute;
  left: 0;
  width: 8px;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
}

.survivor-score__value {
  font-family: 'Rubik Distressed', cursive;
  color: #ffffff;
  font-size: 1rem;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

/* Add a frost effect to the edges */
.survivor-score__thermometer::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 12px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  pointer-events: none;
}

/* Responsive styles */
@media screen and (max-width: 1440px) {
  .survivor-score__thermometer {
    width: 20px;
  }
  
  .survivor-score__value {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1200px) {
  .survivor-score__thermometer {
    width: 18px;
  }
  
  .survivor-score__value {
    font-size: 0.85rem;
  }
} 