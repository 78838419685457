.shop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  display: grid;
  place-items: center;
  z-index: 1000;
}

.shop__window {
  width: min(90vw, 1200px);
  height: min(90vh, 800px);
  background: #0a0a14;
  border-radius: 12px;
  border: 1px solid #33ff66;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.shop__header {
  background: #0f0f1a;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(51, 255, 102, 0.2);
}

.shop__balance {
  font-family: 'Share Tech Mono', monospace;
  font-size: 24px;
  color: #33ff66;
  padding: 8px 16px;
  background: rgba(51, 255, 102, 0.1);
  border-radius: 6px;
  border: 1px solid rgba(51, 255, 102, 0.2);
}

.shop__close {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 8px;
  transition: color 0.2s;
}

.shop__close:hover {
  color: #33ff66;
}

.shop__items {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
  overflow-y: auto;
}

.shop-item {
  background: #0f0f1a;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.2s ease;
  height: 220px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.shop-item:hover {
  border-color: rgba(51, 255, 102, 0.3);
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.shop-item__name {
  font-size: 14px;
  color: #fff;
  margin: 0 0 12px 0;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shop-item__effect {
  font-size: 13px;
  color: #8f96ff;
  margin-bottom: auto;
  line-height: 1.4;
  opacity: 0.9;
  text-align: center;
}

.shop-item__purchase {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.shop-item__price {
  font-family: 'Share Tech Mono', monospace;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  gap: 4px;
}

.shop-item__buy {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  border: none;
  background: #33ff66;
  color: #0a0a14;
  cursor: pointer;
  display: grid;
  place-items: center;
  transition: all 0.2s;
}

.shop-item__buy:hover {
  transform: scale(1.1);
}

.shop-item__buy:disabled {
  background: #ff3366;
  cursor: not-allowed;
  transform: none;
}

.shop-item__icon-circle {
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border: 2px solid;
  margin-bottom: 15px;
}

.shop-item__icon {
  width: 30px;
  height: 30px;
}

.shop-item:hover .shop-item__icon-circle {
  transform: translateX(-50%) scale(1.1);
  box-shadow: 0 0 15px rgba(51, 255, 102, 0.2);
}

/* Icon circle color variations */
.shop-item:nth-child(1) .shop-item__icon-circle { background: rgba(51, 255, 102, 0.1); border-color: rgba(51, 255, 102, 0.3); }
.shop-item:nth-child(1) .shop-item__icon { color: #33ff66; }

.shop-item:nth-child(2) .shop-item__icon-circle { background: rgba(255, 51, 102, 0.1); border-color: rgba(255, 51, 102, 0.3); }
.shop-item:nth-child(2) .shop-item__icon { color: #ff3366; }

.shop-item:nth-child(3) .shop-item__icon-circle { background: rgba(108, 116, 255, 0.1); border-color: rgba(108, 116, 255, 0.3); }
.shop-item:nth-child(3) .shop-item__icon { color: #8f96ff; }

.shop-item:nth-child(4) .shop-item__icon-circle { background: rgba(255, 215, 0, 0.1); border-color: rgba(255, 215, 0, 0.3); }
.shop-item:nth-child(4) .shop-item__icon { color: #ffd700; }

.shop-item:nth-child(5) .shop-item__icon-circle { background: rgba(255, 171, 64, 0.1); border-color: rgba(255, 171, 64, 0.3); }
.shop-item:nth-child(5) .shop-item__icon { color: #ffab40; }

.shop-item:nth-child(6) .shop-item__icon-circle { background: rgba(255, 64, 255, 0.1); border-color: rgba(255, 64, 255, 0.3); }
.shop-item:nth-child(6) .shop-item__icon { color: #ff40ff; }

.shop-item:nth-child(7) .shop-item__icon-circle { background: rgba(64, 255, 255, 0.1); border-color: rgba(64, 255, 255, 0.3); }
.shop-item:nth-child(7) .shop-item__icon { color: #40ffff; }

.shop-item:nth-child(8) .shop-item__icon-circle { background: rgba(171, 71, 188, 0.1); border-color: rgba(171, 71, 188, 0.3); }
.shop-item:nth-child(8) .shop-item__icon { color: #ab47bc; }

.shop-item:nth-child(9) .shop-item__icon-circle { background: rgba(255, 193, 7, 0.1); border-color: rgba(255, 193, 7, 0.3); }
.shop-item:nth-child(9) .shop-item__icon { color: #ffc107; }

.shop-item:nth-child(10) .shop-item__icon-circle { background: rgba(0, 230, 118, 0.1); border-color: rgba(0, 230, 118, 0.3); }
.shop-item:nth-child(10) .shop-item__icon { color: #00e676; }

.shop-item:nth-child(11) .shop-item__icon-circle { background: rgba(41, 121, 255, 0.1); border-color: rgba(41, 121, 255, 0.3); }
.shop-item:nth-child(11) .shop-item__icon { color: #2979ff; }

.shop-item:nth-child(12) .shop-item__icon-circle { background: rgba(213, 0, 249, 0.1); border-color: rgba(213, 0, 249, 0.3); }
.shop-item:nth-child(12) .shop-item__icon { color: #d500f9; }

.shop-item:nth-child(13) .shop-item__icon-circle { background: rgba(255, 145, 0, 0.1); border-color: rgba(255, 145, 0, 0.3); }
.shop-item:nth-child(13) .shop-item__icon { color: #ff9100; }

.shop-item:nth-child(14) .shop-item__icon-circle { background: rgba(29, 233, 182, 0.1); border-color: rgba(29, 233, 182, 0.3); }
.shop-item:nth-child(14) .shop-item__icon { color: #1de9b6; }

.shop-item:nth-child(15) .shop-item__icon-circle { background: rgba(132, 255, 255, 0.1); border-color: rgba(132, 255, 255, 0.3); }
.shop-item:nth-child(15) .shop-item__icon { color: #84ffff; }

.shop-item:nth-child(16) .shop-item__icon-circle { background: rgba(255, 82, 82, 0.1); border-color: rgba(255, 82, 82, 0.3); }
.shop-item:nth-child(16) .shop-item__icon { color: #ff5252; }

.shop-item:nth-child(17) .shop-item__icon-circle { background: rgba(124, 77, 255, 0.1); border-color: rgba(124, 77, 255, 0.3); }
.shop-item:nth-child(17) .shop-item__icon { color: #7c4dff; }

.shop-item:nth-child(18) .shop-item__icon-circle { background: rgba(255, 171, 145, 0.1); border-color: rgba(255, 171, 145, 0.3); }
.shop-item:nth-child(18) .shop-item__icon { color: #ffab91; }

.shop-item:nth-child(19) .shop-item__icon-circle { background: rgba(100, 255, 218, 0.1); border-color: rgba(100, 255, 218, 0.3); }
.shop-item:nth-child(19) .shop-item__icon { color: #64ffda; }

.shop-item:nth-child(20) .shop-item__icon-circle { background: rgba(255, 64, 129, 0.1); border-color: rgba(255, 64, 129, 0.3); }
.shop-item:nth-child(20) .shop-item__icon { color: #ff4081; }

.shop-item:nth-child(21) .shop-item__icon-circle { background: rgba(0, 229, 255, 0.1); border-color: rgba(0, 229, 255, 0.3); }
.shop-item:nth-child(21) .shop-item__icon { color: #00e5ff; }
  