.end-screen-container {
  padding: 10px;
  color: #8f96ff;
  height: 100vh;
  background-color: #141420;
  font-family: 'Share Tech Mono', monospace;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: auto auto 1fr;
  gap: 20px;
  position: relative;
  overflow: hidden;
}

.end-screen-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, transparent, #33ff66, transparent);
  animation: scanline 2s linear infinite;
}

@keyframes scanline {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

.end-screen-header {
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: rgba(35, 35, 54, 0.5);
  border: 1px solid rgba(51, 255, 102, 0.3);
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(51, 255, 102, 0.1);
}

.end-screen-title {
  color: #33ff66;
  font-size: 2em;
  text-shadow: 0 0 10px rgba(51, 255, 102, 0.3);
  margin: 0;
  line-height: 1;
}

.end-screen-score {
  font-size: 2.5em;
  color: #33ff66;
  text-shadow: 0 0 15px rgba(51, 255, 102, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.end-screen-score-label {
  font-size: 0.4em;
  color: #8f96ff;
  opacity: 0.8;
}

.end-screen-metrics {
  background: rgba(35, 35, 54, 0.5);
  border: 1px solid rgba(108, 116, 255, 0.3);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.end-screen-metrics-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.end-screen-metric-card {
  background: rgba(20, 20, 32, 0.8);
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(51, 255, 102, 0.2);
  position: relative;
  overflow: hidden;
}

.end-screen-metric-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, transparent 0%, rgba(51, 255, 102, 0.1) 100%);
  pointer-events: none;
}

.end-screen-metric-label {
  color: #8f96ff;
  font-size: 0.9em;
  opacity: 0.8;
  margin-bottom: 10px;
}

.end-screen-metric-value {
  font-size: 1.8em;
  font-weight: bold;
  color: #33ff66;
  text-shadow: 0 0 10px rgba(51, 255, 102, 0.3);
}

.end-screen-decisions {
  background: rgba(35, 35, 54, 0.5);
  border: 1px solid rgba(108, 116, 255, 0.3);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  
  overflow: hidden;
}

.end-screen-table-container {
  overflow-y: auto;
  margin-top: 10px;
  position: relative;
  height: calc(100% - 50px);
  min-height: 0;
}

.end-screen-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 4px;
}

.end-screen-table th {
  background: rgba(20, 20, 32, 0.8);
  color: #8f96ff;
  padding: 12px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 2;
}

.end-screen-table td {
  padding: 12px;
  background: rgba(20, 20, 32, 0.6);
}

.end-screen-table tr {
  transition: transform 0.2s ease;
}

.end-screen-table tr:hover {
  transform: translateX(5px);
}

.end-screen-row-no-decision td {
  background: rgba(143, 150, 255, 0.05);  /* Subtle blue for no decisions */
}

.end-screen-row-correct td {
  background: rgba(51, 255, 102, 0.1);    /* Green for correct matches */
  border-left: 2px solid rgba(51, 255, 102, 0.5);
}

.end-screen-row-incorrect td {
  background: rgba(255, 51, 102, 0.1);    /* Red for incorrect matches */
  border-left: 2px solid rgba(255, 51, 102, 0.5);
  animation: pulse-error 2s infinite;
}

@keyframes pulse-error {
  0% { background: rgba(255, 51, 102, 0.1); }
  50% { background: rgba(255, 51, 102, 0.15); }
  100% { background: rgba(255, 51, 102, 0.1); }
}

/* Add hover effect that preserves the row color */
.end-screen-table tr:hover td {
  filter: brightness(1.2);
  transform: translateX(5px);
}

.end-screen-flag {
  font-weight: bold;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.end-screen-flag-red {
  color: #ff3366;
  background: rgba(255, 51, 102, 0.1);
}

.end-screen-flag-green {
  color: #33ff66;
  background: rgba(51, 255, 102, 0.1);
}

.end-screen-importance-high {
  color: #ff3366;
  font-weight: bold;
}

.end-screen-victory-message {
  grid-column: 1 / -1;
  text-align: center;
  color: #33ff66;
  font-size: 1.5em;
  padding: 20px;
  background: rgba(51, 255, 102, 0.1);
  border: 1px solid rgba(51, 255, 102, 0.3);
  border-radius: 8px;
  text-shadow: 0 0 10px rgba(51, 255, 102, 0.3);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(51, 255, 102, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(51, 255, 102, 0); }
  100% { box-shadow: 0 0 0 0 rgba(51, 255, 102, 0); }
}

.end-screen-title-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  text-align: left;
}

.end-screen-omnius-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #33ff66;
  box-shadow: 0 0 15px rgba(51, 255, 102, 0.3);
}

.end-screen-subtitle {
  color: #8f96ff;
  font-size: 0.9em;
  margin: 8px 0 0 0;
  opacity: 0.8;
  max-width: 500px;
}

.end-screen-credits {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgba(108, 116, 255, 0.3);
  font-size: 0.9em;
  color: #8f96ff;
  opacity: 0.8;
  text-align: center;
}

.end-screen-credits div {
  margin: 5px 0;
}

.section-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.section-icon {
  color: #33ff66;
  filter: drop-shadow(0 0 5px rgba(51, 255, 102, 0.3));
}

.section-title {
  color: #33ff66;
  font-size: 1.5em;
  margin: 0;
  text-shadow: 0 0 10px rgba(51, 255, 102, 0.3);
}

.end-screen-table .title-column {
  text-align: left;
  padding-right: 20px;
  min-width: 200px;
}

.end-screen-flag-no {
  color: #8f96ff;
  background: rgba(143, 150, 255, 0.1);
}

/* Update existing styles to ensure consistent alignment */
.end-screen-table th,
.end-screen-table td {
  text-align: center;
  padding: 12px;
}

.end-screen-table th.title-column,
.end-screen-table td.title-column {
  text-align: left;
}

.end-screen-round-separator {
  position: sticky;
  top: 40px;
  z-index: 1;
}

.end-screen-round-separator td {
  background: rgba(108, 116, 255, 0.1);
  color: #8f96ff;
  font-weight: bold;
  text-align: left;
  padding: 12px 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid rgba(108, 116, 255, 0.3);
  font-size: 0.9em;
  background: linear-gradient(90deg, rgba(108, 116, 255, 0.1) 0%, rgba(108, 116, 255, 0.05) 50%, rgba(108, 116, 255, 0.01) 100%);
}

/* Update tooltip styles with more specific class names */
.end-screen-scenario-tooltip {
  display: none;
  position: absolute;
  left: 0;
  bottom: 100%;
  margin-bottom: 10px;
  width: max-content;
  max-width: 400px;
  background-color: rgb(20, 20, 32);
  color: #8f96ff;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 0.9em;
  border: 1px solid rgb(108, 116, 255);
  box-shadow: 0 0 20px rgb(0, 0, 0);
  z-index: 10000;
}

.end-screen-scenario-row {
  position: relative;
}

.end-screen-scenario-row:hover .end-screen-scenario-tooltip {
  display: block;
}

.end-screen-scenario-tooltip::after {
  content: '';
  position: absolute;
  bottom: -6px;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #141420;
}

.scenario-description-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.scenario-description-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #141420;
  padding: 20px;
  border: 2px solid #33ff66;
  border-radius: 8px;
  color: #8f96ff;
  max-width: 400px;
  box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.7);
}

.title-column:hover .scenario-description-overlay {
  display: block;
}