.metric {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 6px;
    background: rgba(35, 35, 54, 0.5);
    transition: all 0.3s ease;
  }
  
  .metric:hover {
    background: rgba(35, 35, 54, 0.8);
    transform: translateY(-2px);
  }
  
  .metric-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background: rgba(108, 116, 255, 0.1);
  }
  
  .metric-icon.success {
    color: #33ff66;
    background: rgba(51, 255, 102, 0.1);
  }
  
  .metric-icon.warning {
    color: #ffbb33;
    background: rgba(255, 187, 51, 0.1);
  }
  
  .metric-icon.danger {
    color: #ff3366;
    background: rgba(255, 51, 102, 0.1);
  }
  
  .metric-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .metric-label {
    font-size: 0.7rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.7;
    color: #8f96ff;
  }
  
  .metric-value {
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Share Tech Mono', monospace;
    text-shadow: 0 0 10px rgba(108, 116, 255, 0.3);
  }
  
  .metric-value.success {
    color: #33ff66;
    text-shadow: 0 0 10px rgba(51, 255, 102, 0.3);
  }
  
  .metric-value.warning {
    color: #ffbb33;
    text-shadow: 0 0 10px rgba(255, 187, 51, 0.3);
  }
  
  .metric-value.danger {
    color: #ff3366;
    text-shadow: 0 0 10px rgba(255, 51, 102, 0.3);
  }
  
  /* Add hover tooltip for description */
  .metric {
    position: relative;
    cursor: help;
  }
  
  .metric:hover::after {
    content: attr(title);
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(35, 35, 54, 0.9);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    white-space: nowrap;
    z-index: 1000;
    border: 1px solid rgba(108, 116, 255, 0.3);
  }