.shop-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .shop-container:hover {
    background: rgba(108, 116, 255, 0.1);
  }
  
  .shop-icon {
    position: relative;
    color: #8f96ff;
    transition: all 0.3s ease;
  }
  
  .shop-badge {
    position: absolute;
    top: -8px;
    right: -8px;
    background: #ff3366;
    color: white;
    font-size: 0.7rem;
    min-width: 18px;
    height: 18px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Share Tech Mono', monospace;
    border: 2px solid #1f1f30;
    box-shadow: 0 0 10px rgba(255, 51, 102, 0.5);
    animation: pulse 2s infinite;
  }
  
  .shop-label {
    font-size: 0.7rem;
    color: #8f96ff;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .shop-container:hover .shop-icon {
    transform: translateY(-2px);
    color: #fff;
    filter: drop-shadow(0 0 10px rgba(108, 116, 255, 0.5));
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 51, 102, 0.4);
    }
    70% {
      box-shadow: 0 0 0 6px rgba(255, 51, 102, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 51, 102, 0);
    }
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translate(10px, -50%);
    }
    to {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
  
  .animate-fade-in {
    animation: fade-in 0.3s ease-out forwards;
  }