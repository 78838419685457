.score-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 5px;
  position: relative;
}

.score-grid {
  flex: 1;
  width: 100%;
  background-image: repeating-linear-gradient(
    0deg,
    rgba(108, 116, 255, 0.2) 0px,
    rgba(108, 116, 255, 0.2) 3px,
    transparent 3px,
    transparent 12px
  );
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 15px rgba(108, 116, 255, 0.1);
}

.score-fill {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(108, 116, 255, 0.4);
  transition: height 0.5s ease;
  box-shadow: 
    0 0 15px rgba(108, 116, 255, 0.4),
    0 0 30px rgba(108, 116, 255, 0.3);
}

.score-value-container {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.score-value {
  font-family: 'Share Tech Mono', monospace;
  color: #8f96ff;
  font-size: 1rem;
  text-shadow: 0 0 10px rgba(108, 116, 255, 0.5);
  background: rgba(35, 35, 54, 0.9);
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid rgba(108, 116, 255, 0.3);
  white-space: nowrap;
}

.score-fill::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #8f96ff;
  box-shadow: 
    0 0 10px rgba(108, 116, 255, 0.8),
    0 0 20px rgba(108, 116, 255, 0.4);
}

/* Add scan line effect */
.score-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    transparent 50%,
    rgba(108, 116, 255, 0.02) 50%
  );
  background-size: 100% 4px;
  pointer-events: none;
} 