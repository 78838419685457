.workers {
    position: relative;
    padding: 5px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .workers__container {
    display: flex;
    gap: 10px;
    justify-content: center;
    position: relative;
    padding: 15px;
    border-top: 1px solid rgba(147, 186, 217, 0.2);
    margin-bottom: 40px;
    position: relative;
    user-select: none;
  }
  
  .worker {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    user-select: none;
    -webkit-user-select: none;
  }
  
  .worker__portrait {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(147, 186, 217, 0.3);
    transition: all 0.3s ease;
  }
  
  .worker:hover .worker__portrait {
    border-color: rgba(147, 186, 217, 0.8);
    box-shadow: 
      0 0 10px rgba(147, 186, 217, 0.2),
      0 0 20px rgba(147, 186, 217, 0.1);
    transform: translateY(-2px);
  }
  
  .worker--selected .worker__portrait {
    border-color: #93BAD9;
    background: rgba(147, 186, 217, 0.2);
    animation: selectPulse 0.3s ease-out;
  }
  
  .workers__counter {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(147, 186, 217, 0.2);
    padding: 5px 15px;
    border-radius: 15px;
    color: #ffffff;
    font-size: 0.9rem;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(147, 186, 217, 0.3);
    animation: counterAppear 0.3s ease-out;
  }
  
  /* Remove the container border effect */
  .workers__container::after {
    display: none;
  }
  
  /* Add new selection border */
  .workers__selection-border {
    position: absolute;
    height: calc(100% + 20px);
    top: -10px;
    border: 2px solid rgba(147, 186, 217, 0.3);
    border-radius: 25px;
    pointer-events: none;
    transition: all 0.3s ease;
    animation: borderAppear 0.3s ease-out;
  }
  
  @keyframes borderAppear {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes selectPulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
  
  @keyframes counterAppear {
    from {
      opacity: 0;
      transform: translate(-50%, 10px);
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
  
  /* Frost effect on hover */
  .worker::before {
    content: '';
    position: absolute;
    inset: -5px;
    background: radial-gradient(
      circle at center,
      rgba(147, 186, 217, 0.1) 0%,
      transparent 70%
    );
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
  }
  
  .worker:hover::before {
    opacity: 1;
  }
  
  /* Crews section */
  .workers__crews {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 60px;
    padding: 10px;
  }
  
  .crew-stack {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: grab;
    transition: all 0.3s ease;
  }
  
  .crew-stack::before {
    content: '';
    position: absolute;
    inset: 4px 0 0 0;
    background: rgba(27, 59, 80, 0.9);
    border-radius: 50%;
    border: 2px solid rgba(147, 186, 217, 0.4);
    z-index: -1;
  }
  
  .crew-stack::after {
    content: '';
    position: absolute;
    inset: 2px 0 0 0;
    background: rgba(27, 59, 80, 0.9);
    border-radius: 50%;
    border: 2px solid rgba(147, 186, 217, 0.4);
    z-index: -1;
  }
  
  .crew-stack__count {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgba(27, 59, 80, 0.9);
    border: 2px solid rgba(147, 186, 217, 0.6);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: 'Rubik Distressed', cursive;
    font-size: 1.4rem;
    text-shadow: 0 0 10px rgba(147, 186, 217, 0.5);
    box-shadow: 
      inset 0 0 20px rgba(147, 186, 217, 0.2),
      0 0 15px rgba(0, 0, 0, 0.3);
    animation: stackAppear 0.3s ease-out;
  }
  
  /* Form crew button */
  .workers__form-crew {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    animation: buttonAppear 0.3s ease-out;
    font-size: 0.9rem;
  }
  
  .workers__form-crew .ice-button__content {
    padding: 8px 20px;
  }
  
  .workers__form-crew:hover {
    transform: translateX(-50%) translateY(-2px);
  }
  
  /* Animations */
  @keyframes stackAppear {
    from {
      opacity: 0;
      transform: scale(0.8) translateY(10px);
    }
    to {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
  
  @keyframes buttonAppear {
    from {
      opacity: 0;
      transform: translate(-50%, -10px);
    }
    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
  
  /* Available workers counter */
  .workers__available {
    position: absolute;
    top: -30px;
    right: 20px;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.9rem;
  }
  
  .worker--assigned .worker__portrait {
    background: rgba(0, 0, 0, 0.2);
    border-color: rgba(147, 186, 217, 0.1);
    cursor: not-allowed;
    transform: none;
  }
  
  .worker--assigned::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(45deg, 
      transparent 45%,
      rgba(147, 186, 217, 0.2) 45%,
      rgba(147, 186, 217, 0.2) 55%,
      transparent 55%
    );
    pointer-events: none;
  }
  
  .worker--assigned:hover .worker__portrait {
    border-color: rgba(147, 186, 217, 0.1);
    box-shadow: none;
    transform: none;
  }
  
  .worker--assigned:hover::before {
    opacity: 0;
  }
  
  .crew-stack {
    position: relative;
    cursor: pointer;
  }
  
  .crew-stack__disband-hint {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 20px;
    height: 20px;
    background: rgba(255, 86, 86, 0.9);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 16px;
    opacity: 0;
    transition: all 0.3s ease;
    border: 1px solid #1b3b50;
  }
  
  .crew-stack:hover .crew-stack__disband-hint {
    opacity: 1;
  }
  
  .crew-stack:hover .crew-stack__count {
    border-color: rgba(255, 86, 86, 0.6);
  }
  
  /* Responsive adjustments */
  @media screen and (min-width: 1680px) {
    .worker__portrait {
      width: 35px;
      height: 35px;
    }

    .crew-stack {
      width: 60px;
      height: 60px;
    }

    .crew-stack__count {
      font-size: 1.6rem;
    }

    .workers__container {
      gap: 15px;
      padding: 20px;
    }

    .workers__crews {
      gap: 20px;
      min-height: 70px;
    }

    .workers__form-crew .ice-button__content {
      padding: 10px 25px;
      font-size: 1.1rem;
    }
  }

  @media screen and (max-width: 1440px) {
    .worker__portrait {
      width: 25px;
      height: 25px;
    }

    .crew-stack {
      width: 45px;
      height: 45px;
    }

    .crew-stack__count {
      font-size: 1.2rem;
    }

    .workers__container {
      gap: 8px;
      padding: 12px;
    }

    .workers__crews {
      gap: 12px;
      min-height: 55px;
    }

    .workers__form-crew .ice-button__content {
      padding: 6px 16px;
      font-size: 0.85rem;
    }
  }

  @media screen and (max-width: 1024px) {
    .worker__portrait {
      width: 22px;
      height: 22px;
    }

    .crew-stack {
      width: 40px;
      height: 40px;
    }

    .crew-stack__count {
      font-size: 1rem;
    }

    .workers__container {
      gap: 6px;
      padding: 10px;
    }

    .workers__crews {
      gap: 10px;
      min-height: 50px;
    }

    .workers__form-crew .ice-button__content {
      padding: 5px 14px;
      font-size: 0.8rem;
    }

    .crew-stack__disband-hint {
      width: 16px;
      height: 16px;
      font-size: 14px;
      top: -6px;
      right: -6px;
    }
  }

  /* Adjust animations for smaller screens */
  @media screen and (max-width: 1440px) {
    @keyframes selectPulse {
      0% { transform: scale(1); }
      50% { transform: scale(1.15); }
      100% { transform: scale(1); }
    }

    @keyframes crystalPulse {
      0% { transform: rotate(45deg) scale(1); }
      50% { transform: rotate(45deg) scale(1.15); }
      100% { transform: rotate(45deg) scale(1); }
    }
  }

  .workers__selection-box {
    position: absolute;
    border: 2px solid rgba(147, 186, 217, 0.5);
    background: rgba(147, 186, 217, 0.1);
    pointer-events: none;
    z-index: 1;
  }