.strategy-indicator {
  position: relative;
  display: flex;
  align-items: center;
}

.strategy-indicator__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 12px;
  font-family: 'Rubik Distressed', cursive;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  height: 24px;
  width: 120px;
  clip-path: polygon(
    0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
    80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
    78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
    3% 100%, 0 85%
  );
}

.strategy-indicator__label {
  font-size: 1rem;
  color: #1b3b50;
  position: relative;
  z-index: 1;
  text-shadow: 
    -1px -1px 0 rgba(255, 255, 255, 0.7),
    1px -1px 0 rgba(255, 255, 255, 0.7),
    -1px 1px 0 rgba(255, 255, 255, 0.7),
    1px 1px 0 rgba(255, 255, 255, 0.7);
}

.strategy-indicator__icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.3));
  position: relative;
  z-index: 2;
}

/* Frost crack animation */
@keyframes frostCrack {
  0%, 100% { 
    clip-path: polygon(
      0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
      80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
      78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
      3% 100%, 0 85%
    );
  }
  50% { 
    clip-path: polygon(
      0 13%, 4% 0, 18% 0, 20% 3%, 42% 3%, 44% 0, 58% 0, 60% 3%,
      82% 3%, 84% 0, 96% 0, 100% 13%, 100% 87%, 96% 100%, 82% 100%,
      80% 97%, 58% 97%, 56% 100%, 42% 100%, 40% 97%, 18% 97%, 16% 100%,
      4% 100%, 0 87%
    );
  }
}

.strategy-indicator__content {
  animation: frostCrack 4s infinite ease-in-out;
}

/* Responsive styles */
@media screen and (max-width: 1440px) {
  .strategy-indicator__content {
    height: 22px;
    width: 110px;
    padding: 4px 10px;
  }

  .strategy-indicator__label {
    font-size: 0.9rem;
  }

  .strategy-indicator__icon {
    width: 22px;
    height: 22px;
  }
}

@media screen and (max-width: 1200px) {
  .strategy-indicator__content {
    height: 20px;
    width: 100px;
    padding: 3px 8px;
  }

  .strategy-indicator__label {
    font-size: 0.8rem;
  }

  .strategy-indicator__icon {
    width: 20px;
    height: 20px;
  }
} 