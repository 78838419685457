.worker-particles {
  position: absolute;
  inset: 0;
  pointer-events: none;
  overflow: hidden;
}

.worker-particle {
  position: absolute;
  width: 8px;
  height: 8px;
  left: 50%;
  top: 50%;
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.worker-particle__dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.8;
  position: relative;
}

/* Add trail effect */
.worker-particle__dot::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: fadeTrail 2s linear infinite;
  filter: blur(2px);
}

@keyframes fadeTrail {
  0% {
    opacity: 0.6;
    transform: scale(1) translateX(0);
  }
  100% {
    opacity: 0;
    transform: scale(0.3) translateX(-20px);
  }
}

/* Extended movement animations with more waypoints */
@keyframes moveTopRight {
  0% { transform: translate(0, 0); opacity: 1; }
  20% { transform: translate(100px, -80px); }
  40% { transform: translate(250px, -180px); }
  60% { transform: translate(350px, -220px); }
  80% { transform: translate(400px, -280px); }
  100% { transform: translate(450px, -350px); opacity: 0; }
}

@keyframes moveLeft {
  0% { transform: translate(0, 0); opacity: 1; }
  20% { transform: translate(-150px, 30px); }
  40% { transform: translate(-300px, -20px); }
  60% { transform: translate(-400px, 40px); }
  80% { transform: translate(-500px, 20px); }
  100% { transform: translate(-600px, 0); opacity: 0; }
}

@keyframes moveTop {
  0% { transform: translate(0, 0); opacity: 1; }
  20% { transform: translate(50px, -100px); }
  40% { transform: translate(-30px, -200px); }
  60% { transform: translate(40px, -300px); }
  80% { transform: translate(-20px, -400px); }
  100% { transform: translate(0, -500px); opacity: 0; }
}

@keyframes moveBottomLeft {
  0% { transform: translate(0, 0); opacity: 1; }
  20% { transform: translate(-100px, 80px); }
  40% { transform: translate(-200px, 180px); }
  60% { transform: translate(-300px, 240px); }
  80% { transform: translate(-400px, 280px); }
  100% { transform: translate(-500px, 350px); opacity: 0; }
}

/* Direction-based animations */
.direction-top-right {
  animation-name: moveTopRight;
}

.direction-left {
  animation-name: moveLeft;
}

.direction-top {
  animation-name: moveTop;
}

.direction-bottom-left {
  animation-name: moveBottomLeft;
}

/* Destination pulses */
.destination-pulse {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: pulse 2s ease-out 3;
  opacity: 0;
}

/* Update destination positions to match longer paths */
.destination-pulse.top-right {
  top: 10%;
  right: 10%;
}

.destination-pulse.left {
  top: 50%;
  left: 5%;
}

.destination-pulse.top {
  top: 5%;
  left: 50%;
}

.destination-pulse.bottom-left {
  bottom: 10%;
  left: 10%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(2);
    opacity: 0.4;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
} 