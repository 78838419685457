.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.save-modal {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.save-modal h2 {
  margin: 0 0 20px 0;
  color: #111827;
  font-size: 1.25rem;
}

.save-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.replace-button {
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: #f9fafb;
  color: #374151;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.replace-button:hover {
  background: #f3f4f6;
  border-color: #d1d5db;
}

.save-new-button {
  padding: 12px;
  border: none;
  border-radius: 6px;
  background: #059669;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.save-new-button:hover {
  background: #047857;
}

.cancel-button {
  width: 100%;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: white;
  color: #6b7280;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.cancel-button:hover {
  background: #f9fafb;
}

.warning input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  margin: 16px 0;
  font-size: 0.9rem;
}

.modal-buttons {
  display: flex;
  gap: 12px;
}

.modal-buttons button {
  flex: 1;
}

.confirm-button {
  padding: 12px;
  border: none;
  border-radius: 6px;
  background: #ef4444;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.2s;
}

.confirm-button:disabled {
  background: #fca5a5;
  cursor: not-allowed;
}

.confirm-button:not(:disabled):hover {
  background: #dc2626;
}

.success {
  text-align: center;
}

.success h2 {
  color: #059669;
} 