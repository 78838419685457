.editor-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background: #1e1e1e;
    border-bottom: 1px solid #2d2d2d;
  }
  
  .editor-top-left {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .editor-top-right {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .search-container {
    position: relative;
    width: 300px;
  }
  
  .search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #8b949e;
    pointer-events: none;
  }
  
  .search-input {
    width: 100%;
    padding: 8px 12px 8px 40px;
    border: 1px solid #404040;
    border-radius: 6px;
    font-size: 14px;
    background: #2d2d2d;
    color: #e5e5e5;
    transition: all 0.2s ease;
  }
  
  .search-input::placeholder {
    color: #8b949e;
  }
  
  .search-input:focus {
    outline: none;
    border-color: #4d4d4d;
    background: #333333;
  }
  
  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: none;
    border-radius: 6px;
    background: transparent;
    color: #8b949e;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .icon-button:hover {
    background: #2d2d2d;
    color: #e5e5e5;
  }
  
  .icon-button.primary {
    background: #2d2d2d;
    color: #e5e5e5;
  }
  
  .icon-button.primary:hover {
    background: #404040;
  }