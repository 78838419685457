/* Main container */
.scenarios-view {
  position: relative;
  display: grid;
  grid-template-columns: 180px 1fr 2fr 1fr;
  gap: 24px;
  height: calc(100vh - 120px);
  padding: 24px;
  background: #f9fafb;
  overflow: hidden;
}

/* Make all columns scrollable independently */
.scenario-list-column,
.scenario-info-card,
.scenario-options-column,
.scenario-kpi-column {
  overflow-y: auto;
  max-height: 100%;
  scrollbar-width: thin;
  scrollbar-color: #cbd5e1 transparent;
}

/* Scrollbar styling */
.scenario-list-column::-webkit-scrollbar,
.scenario-info-card::-webkit-scrollbar,
.scenario-options-column::-webkit-scrollbar,
.scenario-kpi-column::-webkit-scrollbar {
  width: 6px;
}

.scenario-list-column::-webkit-scrollbar-track,
.scenario-info-card::-webkit-scrollbar-track,
.scenario-options-column::-webkit-scrollbar-track,
.scenario-kpi-column::-webkit-scrollbar-track {
  background: transparent;
}

.scenario-list-column::-webkit-scrollbar-thumb,
.scenario-info-card::-webkit-scrollbar-thumb,
.scenario-options-column::-webkit-scrollbar-thumb,
.scenario-kpi-column::-webkit-scrollbar-thumb {
  background-color: #cbd5e1;
  border-radius: 3px;
}

/* Column 1: Scenario List */
.scenario-list-column {
  width: 180px;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  height: 100%; /* Ensure full height */
}

/* Make the scenarios list scrollable while keeping add buttons visible */
.scenario-list-items {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
  flex: 1; /* Take remaining space */
  margin-bottom: 16px;
  min-height: 0; /* Allow content to scroll */
}

.scenario-list-item {
  position: relative;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 8px 10px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  flex-shrink: 0; /* Prevent items from shrinking */
}

.scenario-list-item:hover:not(.selected) {
  border-color: #64748b;
  background: #f1f5f9;
}

.scenario-list-item.selected {
  border-color: #3b82f6;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.scenario-list-number {
  font-size: 0.65rem;
  font-weight: 600;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.scenario-list-title {
  font-size: 0.8rem;
  font-weight: 600;
  color: #334155;
  margin: 2px 0 0 0;
  line-height: 1.2;
}

.scenario-list-preview {
  display: none;
}

.scenario-delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #fee2e2;
  border: none;
  color: #ef4444;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s;
}

.scenario-list-item:hover .scenario-delete-button {
  opacity: 1;
}

.scenario-delete-button:hover {
  background: #fecaca;
  transform: scale(1.05);
}

.scenario-add-button,
.scenario-ai-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background: white;
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  color: #64748b;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%; /* Take full width */
}

.scenario-add-button:hover,
.scenario-ai-button:hover {
  border-color: #64748b;
  background: #f8fafc;
  color: #475569;
}

.scenario-add-button svg,
.scenario-ai-button svg {
  transition: transform 0.2s;
}

.scenario-add-button:hover svg {
  transform: rotate(90deg);
}

.scenario-ai-button:hover svg {
  transform: rotate(180deg);
}

/* Column 2: Scenario Information */
.scenario-info-card {
  background: white;
  border-radius: 12px;
  box-shadow: none;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 1px solid #3b82f6;
  position: relative;
}

.scenario-info-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.scenario-info-label {
  font-size: 0.85rem;
  font-weight: 600;
  color: #334155;
}

.scenario-info-input {
  height: 36px;
  padding: 0 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #f8fafc;
}

.scenario-info-textarea {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #f8fafc;
  resize: none;
  flex: 1;
  font-family: inherit;
}

/* Add these styles for the datetime fields */
.scenario-datetime-row {
  display: flex;
  gap: 12px;
  margin-bottom: 8px;
}

.scenario-datetime-field {
  flex: 1;
  min-width: 0;
}

.scenario-info-input.datetime {
  font-family: monospace;
  font-size: 0.85rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Make the date field slightly larger than the time field */
.scenario-datetime-field:first-child {
  flex: 3;
}

.scenario-datetime-field:last-child {
  flex: 2;
}

/* Column 3: Option Cards */
.scenario-options-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
}

.scenario-option-header {
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 16px;
}

.option-1 .scenario-option-header {
  background: #E8D5C4;
  color: #1e293b;
}

.option-2 .scenario-option-header {
  background: #C4D7E8;
  color: #1e293b;
}

/* Add new classes for option card inputs */
.scenario-option-input {
  width: 100%;
  height: 36px;
  padding: 0 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #f8fafc;
  min-width: 0;
  box-sizing: border-box;
}

.scenario-option-scrollable-input {
  width: 100%;
  height: 48px;
  padding: 6px 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #f8fafc;
  overflow-y: auto;
  white-space: pre-wrap;
  line-height: 1.5;
  min-width: 0;
  box-sizing: border-box;
}

/* Focus states */
.scenario-option-input:focus,
.scenario-option-scrollable-input:focus {
  outline: none;
  border-color: #3b82f6;
  background: white;
}

/* Column 4: KPI Cards */
.scenario-kpi-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
}

.scenario-kpi-card {
  background: white;
  border-radius: 12px;
  box-shadow: none;
  padding: 16px;
  flex: 1;
  max-height: calc(50% - 8px);
  overflow-y: auto;
  border: 1px solid #3b82f6;
}

/* Update KPI styles */
.scenario-kpi-row {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;
  border-bottom: 1px solid #e2e8f0;
}

.scenario-kpi-row:last-child {
  border-bottom: none;
}

.scenario-kpi-list {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}

.scenario-kpi-name {
  flex: 1;
  font-size: 0.85rem;
  color: #334155;
}

.scenario-kpi-inputs {
  display: flex;
  gap: 8px;
}

.scenario-kpi-input-group {
  display: flex;
  align-items: center;
  gap: 2px;
}

.scenario-kpi-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #64748b;
  margin-right: 4px;
}

.scenario-kpi-label svg {
  stroke-width: 2px;
}

/* Add hover tooltip styling */
.scenario-kpi-label:hover {
  cursor: help;
}

/* Remove the header styles since we don't need them anymore */
.scenario-kpi-row.header,
.scenario-kpi-value {
  display: none;
}

/* Input focus states */
.scenario-info-input:focus,
.scenario-info-textarea:focus,
.scenario-kpi-input:focus {
  outline: none;
  border-color: #3b82f6;
  background: white;
}

/* Update KPI input styles */
.scenario-kpi-input {
  width: 40px;
  height: 32px;
  padding: 0;
  border: none;
  background: transparent;
  text-align: center;
  font-size: 0.85rem;
  color: #334155;
  font-weight: 500;
  -moz-appearance: textfield; /* Remove spinner for Firefox */
}

/* Remove spinner buttons for Chrome/Safari/Edge */
.scenario-kpi-input::-webkit-outer-spin-button,
.scenario-kpi-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Add subtle hover and focus states */
.scenario-kpi-input:hover {
  background: #f1f5f9;
  border-radius: 4px;
}

.scenario-kpi-input:focus {
  outline: none;
  background: #f1f5f9;
  border-radius: 4px;
}

/* Update the KPI row to show the input more inline */
.scenario-kpi-row {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px 8px;
  border-radius: 4px;
}

.scenario-kpi-row:hover {
  background: #f8fafc;
}

/* Update the section title style in option cards to match scenario info card */
.scenario-section-title {
  font-size: 0.85rem;
  font-weight: 600;
  color: #334155;
  margin-bottom: 4px;
}

/* Add label style to match scenario info card */
.scenario-option-card .scenario-section-title {
  font-size: 0.85rem;
  font-weight: 600;
  color: #334155;
  margin-bottom: 4px;
}

/* Remove any conflicting styles */
.scenario-option-card {
  background: white;
  border-radius: 12px;
  box-shadow: none;
  padding: 16px 16px 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  min-width: 0;
  box-sizing: border-box;
  flex: 1;
  max-height: calc(50% - 8px);
  overflow-y: auto;
  border: 1px solid #3b82f6;
}

/* Update scenario info field styles */
.scenario-info-field {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Add subtitle style */
.scenario-info-subtitle {
  font-size: 0.75rem;
  color: #64748b;
  margin: -2px 0 4px 0;
}

/* Make the description field container take remaining space */
.scenario-info-field.description {
  flex: 1; /* Take remaining space */
  display: flex;
  flex-direction: column;
}

/* Style the buttons container */
.scenario-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 8px;
  margin-top: auto; /* Push to bottom */
  flex-shrink: 0; /* Prevent buttons from shrinking */
}

/* Style the AI button similar to add button */
.scenario-ai-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background: white;
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  color: #64748b;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%; /* Take full width */
}

.scenario-ai-button:hover {
  border-color: #3b82f6;
  background: #f8fafc;
  color: #3b82f6;
}

.scenario-ai-button svg {
  transition: transform 0.2s;
}

.scenario-ai-button:hover svg {
  transform: rotate(180deg);
}

/* Style the AI generate button */
.ai-generate-button {
  opacity: 0;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Show AI button on hover */
.scenario-info-header:hover .ai-generate-button,
.scenario-option-header:hover .ai-generate-button,
.scenario-kpi-card:hover .ai-generate-button {
  opacity: 1;
}

/* Position the AI button in headers */
.scenario-info-header,
.scenario-option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Style AI button hover states */
.ai-generate-button:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: scale(1.1);
}

/* Position KPI card AI button */
.scenario-kpi-card {
  position: relative;
}

.ai-generate-button.kpi {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #64748b;
}

.ai-generate-button.kpi:hover {
  background: #f1f5f9;
}

/* Add back the header styles */
.scenario-info-header {
  background: #1e293b;
  color: white;
  padding: 12px 16px;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Add styles for the AI button above title */
.scenario-info-card {
  position: relative; /* Ensure proper positioning of the AI button */
}

.scenario-card-ai-button {
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: white;
  border: 1px solid #3b82f6;
  color: #3b82f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.scenario-card-ai-button:hover {
  transform: translateX(-50%) scale(1.1);
  background: #2563eb;
  color: white;
}

/* Add hover states for all cards */
.scenario-info-card:hover .scenario-card-ai-button,
.scenario-option-card:hover .scenario-card-ai-button,
.scenario-kpi-card:hover .scenario-card-ai-button {
  opacity: 1;
}

/* Update card positioning and content */
.scenario-info-card,
.scenario-option-card,
.scenario-kpi-card {
  position: relative;
  padding: 24px 16px 0px;
  margin-top: 14px;
}

.scenario-info-content,
.scenario-option-content,
.scenario-kpi-content {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Update AI button positioning */
.scenario-card-ai-button {
  position: absolute;
  top: -14px;
  left: 98%;
  transform: translateX(-50%);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: white;
  border: 1px solid #3b82f6;
  color: #3b82f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

/* Update hover states */
.scenario-info-card:hover .scenario-card-ai-button,
.scenario-option-card:hover .scenario-card-ai-button,
.scenario-kpi-card:hover .scenario-card-ai-button {
  opacity: 1;
}

.scenario-card-ai-button:hover {
  transform: translateX(-50%) scale(1.1);
  background: #2563eb;
  color: white;
}

/* Add styles for the impacts row */
.scenario-impacts-row {
  display: flex;
  gap: 12px;
  margin-top: 8px;
  flex: 1; /* Take remaining space */
}

.scenario-impact-column {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Update the option card content to use flex */
.scenario-option-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Update the scrollable input for impacts */
.scenario-impact-column .scenario-option-scrollable-input {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #f8fafc;
  resize: none;
  font-family: inherit;
  min-height: 80px; /* Set a minimum height */
  flex: 1;
}

.scenario-impact-column .scenario-option-scrollable-input:focus {
  outline: none;
  border-color: #3b82f6;
  background: white;
}

/* Add style for the reply textarea */
.scenario-option-scrollable-input.reply {
  min-height: 56px; /* Increased from 48px */
  height: 120px; /* Increased from 48px */
  resize: none;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #f8fafc;
  font-family: inherit;
  line-height: 1.2;
}

/* Add styles for floating labels */
.scenario-input-group {
  position: relative;
  margin-bottom: 16px;
}

.scenario-input-group .scenario-section-title {
  position: absolute;
  top: -10px;
  left: 12px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #3b82f6;
  background: white;
  padding: 0 6px;
  margin: 0;
  z-index: 1;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.scenario-input-group .scenario-option-input,
.scenario-input-group .scenario-option-scrollable-input {
  width: 100%;
  margin-top: 0;
}

/* Add style for the option textarea */
.scenario-option-scrollable-input.option {
  min-height: 56px; /* Increased from 48px */
  height: 56px; /* Increased from 48px */
  resize: none;
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #f8fafc;
  font-family: inherit;
  line-height: 1.2;
}

/* Update the impacts row to take remaining space */
.scenario-impacts-row {
  display: flex;
  gap: 12px;
  margin-top: 8px;
  flex: 1;
  min-height: 0; /* Important for flex to work properly */
}

.scenario-impact-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* Important for flex to work properly */
}

.scenario-impact-column .scenario-input-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; /* Important for flex to work properly */
}

.scenario-impact-column .scenario-option-scrollable-input {
  flex: 1;
  min-height: 0; /* Allow it to shrink */
}

/* Add style for flex-grow input group */
.scenario-input-group.flex-grow {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 120px; /* Set minimum height for both message and clues */
}

.scenario-input-group.flex-grow .scenario-option-scrollable-input {
  flex: 1;
  min-height: 100px; /* Ensure minimum height for the textarea itself */
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  background: #f8fafc;
  resize: none;
  font-family: inherit;
}

/* Make sure the info card content uses flex */
.scenario-info-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Remove old info card styles */
.scenario-info-label,
.scenario-info-subtitle,
.scenario-info-input,
.scenario-info-textarea {
  display: none;
}

/* Update the floating label style */
.scenario-input-group .scenario-section-title {
  position: absolute;
  top: -10px;
  left: 12px;
  font-size: 0.7rem;
  font-weight: 600;
  color: #3b82f6;
  background: white;
  padding: 0 6px;
  margin: 0;
  z-index: 1;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

/* Add a subtle transition for hover state */
.scenario-input-group:hover .scenario-section-title {
  color: #2563eb;
}

/* Add styles for the scenario list AI button */
.scenario-list-ai-button {
  position: absolute;
  top: 8px;
  right: 40px; /* Position it next to delete button */
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background: #e0f2fe; /* Light blue background */
  border: none;
  color: #3b82f6;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.2s;
}

.scenario-list-item:hover .scenario-list-ai-button {
  opacity: 1;
}

.scenario-list-ai-button:hover {
  background: #bfdbfe;
  transform: scale(1.05);
}

/* Update delete button position to accommodate AI button */
.scenario-delete-button {
  right: 8px; /* Keep this value */
}

.sender-avatar-group {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.sender-section {
  flex: 1;
}

.avatar-section {
  width: 80px;
}

.avatar-upload {
  position: relative;
  width: 60px;
  height: 60px;
}

.avatar-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.avatar-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px dashed #e2e8f0;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.2s;
}

.avatar-label:hover {
  border-color: #94a3b8;
}

.avatar-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #94a3b8;
  background: #f8fafc;
}
