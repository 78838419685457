.helper-bot {
    position: fixed;
    top: 0;
    right: -400px;
    width: 400px;
    height: 100vh;
    background: linear-gradient(135deg, 
      rgba(31, 31, 48, 0.95) 0%,
      rgba(41, 41, 62, 0.95) 100%
    );
    backdrop-filter: blur(10px);
    border-left: 1px solid rgba(255, 51, 102, 0.3);
    display: flex;
    flex-direction: column;
    transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1000;
    box-shadow: 
      -5px 0 20px rgba(0, 0, 0, 0.3),
      -10px 0 40px rgba(255, 51, 102, 0.1);
    animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .helper-bot.open {
    right: 0;
  }
  
  .helper-bot-header {
    padding: 20px;
    background: linear-gradient(90deg, 
      rgba(255, 51, 102, 0.1),
      rgba(153, 51, 255, 0.1)
    );
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid rgba(255, 51, 102, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .helper-bot-header::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 51, 102, 0.2),
      transparent
    );
    animation: scanline 4s linear infinite;
  }
  
  .helper-bot-title {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #ff3366;
    font-family: 'Share Tech Mono', monospace;
    font-size: 1.2rem;
  }
  
  .helper-bot-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid rgba(255, 51, 102, 0.5);
    animation: rotateAvatar 10s linear infinite;
    position: relative;
  }
  
  .helper-bot-avatar::before {
    content: '';
    position: absolute;
    inset: -2px;
    background: conic-gradient(
      from 0deg,
      transparent,
      rgba(255, 51, 102, 0.8) 45deg,
      rgba(255, 51, 102, 0.3) 180deg,
      transparent 360deg
    );
    animation: rotate 4s linear infinite;
    border-radius: 50%;
  }
  
  .helper-bot-close {
    background: none;
    border: none;
    color: #ff3366;
    cursor: pointer;
    padding: 5px;
    transition: all 0.3s ease;
  }
  
  .helper-bot-close:hover {
    color: #fff;
    transform: rotate(90deg);
  }
  
  .helper-bot-messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .message {
    display: flex;
    gap: 12px;
    max-width: 80%;
  }
  
  .message.bot {
    align-self: flex-start;
  }
  
  .message.user {
    align-self: flex-end;
    flex-direction: row-reverse;
  }
  
  .message-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgba(255, 51, 102, 0.3);
  }
  
  .message-content {
    background: linear-gradient(135deg,
      rgba(255, 51, 102, 0.1),
      rgba(153, 51, 255, 0.1)
    );
    padding: 12px;
    border-radius: 12px;
    color: rgba(255, 255, 255, 0.9);
    font-family: 'Share Tech Mono', monospace;
    font-size: 0.9rem;
    border: 1px solid rgba(255, 51, 102, 0.2);
    position: relative;
    overflow: hidden;
    animation: glowPulse 2s infinite;
  }
  
  .message.bot .message-content {
    background: linear-gradient(135deg,
      rgba(255, 51, 102, 0.1),
      rgba(153, 51, 255, 0.1)
    );
    animation: glowPulse 2s infinite;
  }
  
  .message.bot .message-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 51, 102, 0.1),
      transparent
    );
    animation: scanline 3s linear infinite;
  }
  
  .message.user .message-content {
    background: rgba(153, 51, 255, 0.1);
    border-color: rgba(153, 51, 255, 0.2);
  }
  
  .helper-bot-input {
    padding: 20px;
    background: linear-gradient(90deg,
      rgba(31, 31, 48, 0.98),
      rgba(41, 41, 62, 0.98)
    );
    position: relative;
    overflow: hidden;
    border-top: 1px solid rgba(255, 51, 102, 0.3);
    display: flex;
    gap: 12px;
  }
  
  .helper-bot-input::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 2px;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 51, 102, 0.5),
      transparent
    );
    animation: scanline 2s linear infinite;
  }
  
  .helper-bot-input input {
    flex: 1;
    background: rgba(0, 0, 0, 0.3);
    box-shadow: 
      0 0 10px rgba(255, 51, 102, 0.1),
      inset 0 0 5px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 51, 102, 0.3);
    padding: 12px;
    border-radius: 6px;
    color: #fff;
    font-family: 'Share Tech Mono', monospace;
    transition: all 0.3s ease;
  }
  
  .helper-bot-input input:focus {
    outline: none;
    border-color: rgba(255, 51, 102, 0.6);
    box-shadow: 
      0 0 15px rgba(255, 51, 102, 0.2),
      inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: translateY(-1px);
  }
  
  .helper-bot-input button {
    background: none;
    border: none;
    color: #ff3366;
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s ease;
  }
  
  .helper-bot-input button:hover {
    color: #fff;
    transform: translateX(2px);
  }
  
  /* Scrollbar styling */
  .helper-bot-messages::-webkit-scrollbar {
    width: 6px;
  }
  
  .helper-bot-messages::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
  
  .helper-bot-messages::-webkit-scrollbar-thumb {
    background: rgba(255, 51, 102, 0.3);
    border-radius: 3px;
  }
  
  .helper-bot-messages::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 51, 102, 0.5);
  }
  
  /* New Animations */
  @keyframes scanline {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
  
  @keyframes rotateAvatar {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  
  @keyframes glowPulse {
    0%, 100% { box-shadow: 0 0 10px rgba(255, 51, 102, 0.2); }
    50% { box-shadow: 0 0 20px rgba(255, 51, 102, 0.4); }
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  /* Add a typing indicator animation */
  .message.bot.typing .message-content::after {
    content: '...';
    animation: typing 1s infinite;
  }
  
  @keyframes typing {
    0%, 100% { content: '.'; }
    33% { content: '..'; }
    66% { content: '...'; }
  }
  
  /* Add these new styles */
  
  .helper-bot-status {
    font-size: 0.8rem;
    color: #ff3366;
    font-family: 'Share Tech Mono', monospace;
    animation: blink 2s infinite;
  }
  
  .message.system .message-content {
    background: rgba(108, 116, 255, 0.1);
    border-color: rgba(108, 116, 255, 0.3);
    font-family: 'Share Tech Mono', monospace;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .message.warning .message-content {
    background: rgba(255, 204, 0, 0.1);
    border-color: rgba(255, 204, 0, 0.3);
    color: #ffcc00;
  }
  
  .message.evil .message-content {
    background: rgba(255, 51, 102, 0.1);
    border-color: rgba(255, 51, 102, 0.3);
    color: #ff3366;
  }
  
  .message-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .message-content svg {
    flex-shrink: 0;
  }
  
  .typing-indicator {
    display: flex;
    gap: 4px;
  }
  
  .typing-indicator span {
    animation: typingDot 1s infinite;
    opacity: 0.5;
  }
  
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .helper-bot-input input:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .message {
    opacity: 0;
    animation: messageAppear 0.3s ease forwards;
  }
  
  /* New animations */
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }
  
  @keyframes typingDot {
    0%, 100% { opacity: 0.3; }
    50% { opacity: 1; }
  }
  
  @keyframes messageAppear {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Add glitch effect to system messages */
  .message.system .message-content {
    position: relative;
    overflow: hidden;
  }
  
  .message.system .message-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(108, 116, 255, 0.2),
      transparent
    );
    animation: glitch 2s linear infinite;
  }
  
  /* Evil message effect */
  .message.evil .message-content::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      45deg,
      transparent 0%,
      rgba(255, 51, 102, 0.1) 50%,
      transparent 100%
    );
    animation: evilGlow 3s ease-in-out infinite;
  }
  
  @keyframes evilGlow {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  /* Warning message effect */
  .message.warning .message-content {
    animation: warningPulse 2s infinite;
  }
  
  @keyframes warningPulse {
    0%, 100% {
      box-shadow: 0 0 10px rgba(255, 204, 0, 0.2);
    }
    50% {
      box-shadow: 0 0 20px rgba(255, 204, 0, 0.4);
    }
  }
  
  /* Add scanlines to the entire chat */
  .helper-bot::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: repeating-linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0.1) 1px,
      transparent 1px,
      transparent 2px
    );
    pointer-events: none;
    z-index: 1;
  }
  
  /* Purge effect styles */
  .purging .helper-bot-messages {
    animation: glitchEffect 0.3s infinite;
  }
  
  .purging .message {
    animation: fadeOutGlitch 0.5s forwards;
  }
  
  @keyframes glitchEffect {
    0% {
      transform: translate(0);
    }
    20% {
      transform: translate(-2px, 2px);
    }
    40% {
      transform: translate(2px, -2px);
    }
    60% {
      transform: translate(-2px, -2px);
    }
    80% {
      transform: translate(2px, 2px);
    }
    100% {
      transform: translate(0);
    }
  }
  
  @keyframes fadeOutGlitch {
    0% {
      opacity: 1;
      transform: translate(0);
    }
    20% {
      transform: translate(-5px, 5px);
    }
    40% {
      transform: translate(5px, -5px);
    }
    60% {
      transform: translate(-5px, -5px);
    }
    80% {
      transform: translate(5px, 5px);
    }
    100% {
      opacity: 0;
      transform: translate(0);
    }
  }
  
  .purging .helper-bot-input {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .message.system .message-content {
    position: relative;
    overflow: hidden;
  }
  
  .purging .message.system .message-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 300%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 51, 102, 0.5),
      transparent
    );
    animation: purgeGlitch 0.5s linear infinite;
  }
  
  @keyframes purgeGlitch {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }