.top-bar {
  height: 60px;
  width: 100%;
  background-color: #232336;
  border-bottom: 1px solid rgba(108, 116, 255, 0.3);
  color: rgba(108, 116, 255, 0.9);
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-family: 'Share Tech Mono', monospace;
  position: relative;
}

.metrics-container {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 0 40px;
}

@keyframes guide-pulse {
  0%, 100% {
    box-shadow: 0 0 0 0 rgba(255, 51, 102, 0.7);
    border-color: rgba(255, 51, 102, 0.7);
  }
  50% {
    box-shadow: 0 0 0 4px rgba(108, 116, 255, 0.7);
    border-color: rgba(108, 116, 255, 0.7);
  }
}

.animate-guide-pulse {
  animation: guide-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
} 