.resources {
  width: 60px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin: 5px;
}

.resource {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resource__icon {
  width: 55px;
  height: 55px;
  object-fit: contain;
}

.resource__value {
  font-family: 'Rubik Distressed', cursive;
  font-size: 1.3rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.3);
}

/* 1440px - 1200px */
@media screen and (max-width: 1440px) {
  .resource__icon {
    width: 50px;
    height: 50px;
  }

  .resource__value {
    font-size: 1.1rem;
  }
}

/* 1200px - 1024px */
@media screen and (max-width: 1200px) {
  .resource__icon {
    width: 40px;
    height: 40px;
  }

  .resource__value {
    font-size: 1rem;
  }
}

/* Below 1024px */
@media screen and (max-width: 1024px) {
  .resource__icon {
    width: 30px;
    height: 30px;
  }

  .resource__value {
    font-size: 0.9rem;
  }
} 