.survival-tracker {
  width: 60px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin: 5px;
}

.survival-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survival-item__icon {
  width: 45px;
  height: 45px;
  object-fit: contain;
}

.survival-item__value {
  font-family: 'Rubik Distressed', cursive;
  font-size: 1.3rem;
  color: #ffffff;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.3);
}

/* 1440px - 1200px */
@media screen and (max-width: 1440px) {
  .survival-item__icon {
    width: 45px;
    height: 45px;
  }

  .survival-item__value {
    font-size: 1.1rem;
  }

  .survival-tracker {
    gap: 8px;
  }
}

/* 1200px - 1024px */
@media screen and (max-width: 1200px) {
  .survival-item__icon {
    width: 35px;
    height: 35px;
  }

  .survival-item__value {
    font-size: 1rem;
  }

  .survival-tracker {
    gap: 6px;
    padding: 12px;
  }
}

/* Below 1024px */
@media screen and (max-width: 1024px) {
  .survival-item__icon {
    width: 25px;
    height: 25px;
  }

  .survival-item__value {
    font-size: 0.9rem;
  }

  .survival-tracker {
    gap: 5px;
    padding: 10px;
  }
} 