.antarctica-top-bar {
  height: 60px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.antarctica-top-bar::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.antarctica-top-bar__content {
  height: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.antarctica-top-bar__left {
  flex: 1;
  display: flex;
  align-items: center;
}

.antarctica-top-bar__center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 20px;
}

.antarctica-top-bar__right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.antarctica-top-bar__separator {
  width: 1px;
  height: 30px;
  background: rgba(255, 255, 255, 0.2);
} 