.round-tracker {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 20px;
  font-family: 'Rubik Distressed', cursive;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  clip-path: polygon(
    0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
    80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
    78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
    3% 100%, 0 85%
  );
}

/* Ice crack effect */
.round-tracker::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.4) 50%,
    rgba(255, 255, 255, 0.9) 100%
  );
  clip-path: polygon(
    20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%
  );
  z-index: 0;
}

.round-tracker__label {
  font-size: 1.1rem;
  color: #1b3b50;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  text-shadow: 
    -1px -1px 0 rgba(255, 255, 255, 0.7),
    1px -1px 0 rgba(255, 255, 255, 0.7),
    -1px 1px 0 rgba(255, 255, 255, 0.7),
    1px 1px 0 rgba(255, 255, 255, 0.7);
}

.round-tracker__count {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.4rem;
  position: relative;
  z-index: 1;
}

.round-tracker__current {
  color: #1b3b50;
  text-shadow: 
    -1px -1px 0 rgba(255, 255, 255, 0.7),
    1px -1px 0 rgba(255, 255, 255, 0.7),
    -1px 1px 0 rgba(255, 255, 255, 0.7),
    1px 1px 0 rgba(255, 255, 255, 0.7);
}

.round-tracker__separator {
  color: #1b3b50;
  margin: 0 -2px;
  font-size: 1.2rem;
  opacity: 0.7;
}

.round-tracker__total {
  color: #1b3b50;
  opacity: 0.7;
}

/* Frost crack animation */
@keyframes frostCrack {
  0%, 100% { 
    clip-path: polygon(
      0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
      80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
      78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
      3% 100%, 0 85%
    );
  }
  50% { 
    clip-path: polygon(
      0 13%, 4% 0, 18% 0, 20% 3%, 42% 3%, 44% 0, 58% 0, 60% 3%,
      82% 3%, 84% 0, 96% 0, 100% 13%, 100% 87%, 96% 100%, 82% 100%,
      80% 97%, 58% 97%, 56% 100%, 42% 100%, 40% 97%, 18% 97%, 16% 100%,
      4% 100%, 0 87%
    );
  }
}

.round-tracker {
  animation: frostCrack 4s infinite ease-in-out;
}

/* Responsive styles */
@media screen and (max-width: 1440px) {
  .round-tracker {
    padding: 7px 18px;
    gap: 14px;
  }

  .round-tracker__label {
    font-size: 1rem;
  }

  .round-tracker__count {
    font-size: 1.3rem;
    gap: 7px;
  }

  .round-tracker__separator {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1200px) {
  .round-tracker {
    padding: 6px 16px;
    gap: 10px;
  }

  .round-tracker__label {
    font-size: 0.85rem;
  }

  .round-tracker__count {
    font-size: 1.1rem;
    gap: 5px;
  }

  .round-tracker__separator {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 1024px) {
  .round-tracker {
    padding: 5px 14px;
    gap: 8px;
  }

  .round-tracker__label {
    font-size: 0.75rem;
  }

  .round-tracker__count {
    font-size: 0.95rem;
    gap: 4px;
  }

  .round-tracker__separator {
    font-size: 0.85rem;
  }
}

/* Keep existing mobile styles for even smaller screens */
@media screen and (max-width: 768px) {
  .round-tracker {
    padding: 4px 12px;
    gap: 6px;
  }

  .round-tracker__label {
    font-size: 0.7rem;
  }

  .round-tracker__count {
    font-size: 0.9rem;
    gap: 3px;
  }

  .round-tracker__separator {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 480px) {
  .round-tracker {
    padding: 3px 10px;
    gap: 5px;
  }

  .round-tracker__label {
    font-size: 0.65rem;
  }

  .round-tracker__count {
    font-size: 0.85rem;
    gap: 2px;
  }

  .round-tracker__separator {
    font-size: 0.75rem;
  }
} 