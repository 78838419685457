.antarctica-bottom-bar {
  height: 60px;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.antarctica-bottom-bar__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.antarctica-bottom-bar__container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.antarctica-bottom-bar__title {
  font-family: 'Rubik Distressed', cursive;
  color: #ffffff;
  font-size: 1.2rem;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  margin: 0;
  position: absolute;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease;
}

.antarctica-bottom-bar__actions {
  position: absolute;
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.5s ease;
}

/* Show/Hide animations */
.antarctica-bottom-bar__container.show-title .antarctica-bottom-bar__title {
  opacity: 1;
  transform: translateY(0);
}

.antarctica-bottom-bar__container.show-title .antarctica-bottom-bar__actions {
  opacity: 0;
  transform: translateY(-20px);
}

.antarctica-bottom-bar__container.show-button .antarctica-bottom-bar__title {
  opacity: 0;
  transform: translateY(20px);
}

.antarctica-bottom-bar__container.show-button .antarctica-bottom-bar__actions {
  opacity: 1;
  transform: translateY(0);
}

/* Add frost effect when button appears */
.antarctica-bottom-bar__container.show-button::before {
  content: '';
  position: absolute;
  inset: -10px;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.1) 0%,
    transparent 70%
  );
  opacity: 0;
  animation: frostPulse 2s ease-out forwards;
}

@keyframes frostPulse {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

/* Responsive styles */
@media screen and (max-width: 1024px) {
  .antarctica-bottom-bar__title {
    font-size: 1rem;
  }
}

.antarctica-bottom-bar__container.show-tasks .antarctica-bottom-bar__title--tasks {
  opacity: 1;
  transform: translateY(0);
}

.antarctica-bottom-bar__container.show-tasks .antarctica-bottom-bar__actions,
.antarctica-bottom-bar__container.show-tasks .antarctica-bottom-bar__title:not(.antarctica-bottom-bar__title--tasks) {
  opacity: 0;
  transform: translateY(20px);
}

.antarctica-bottom-bar__title--tasks {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.5s ease;
  position: absolute;
}

/* Phase-based animations */
.antarctica-bottom-bar__container.choose .antarctica-bottom-bar__title:not(.antarctica-bottom-bar__title--tasks) {
  opacity: 1;
  transform: translateY(0);
}

.antarctica-bottom-bar__container.choose .antarctica-bottom-bar__actions,
.antarctica-bottom-bar__container.choose .antarctica-bottom-bar__title--tasks {
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none;
}

.antarctica-bottom-bar__container.confirm .antarctica-bottom-bar__actions {
  opacity: 1;
  transform: translateY(0);
}

.antarctica-bottom-bar__container.confirm .antarctica-bottom-bar__title,
.antarctica-bottom-bar__container.confirm .antarctica-bottom-bar__title--tasks {
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none;
}

.antarctica-bottom-bar__container.assign .antarctica-bottom-bar__title--tasks {
  opacity: 1;
  transform: translateY(0);
}

.antarctica-bottom-bar__container.assign .antarctica-bottom-bar__actions,
.antarctica-bottom-bar__container.assign .antarctica-bottom-bar__title:not(.antarctica-bottom-bar__title--tasks) {
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none;
} 