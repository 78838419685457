.impact-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(20, 20, 32, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    backdrop-filter: blur(8px);
  }
  
  .impact-alert {
    background: #1f1f30;
    border-radius: 12px;
    padding: 30px;
    width: 90%;
    max-width: 500px;
    position: relative;
    border: 1px solid rgba(108, 116, 255, 0.3);
    box-shadow: 
      0 0 50px rgba(0, 0, 0, 0.5),
      0 0 100px rgba(108, 116, 255, 0.1);
    animation: impactSlideIn 0.3s ease-out;
  }
  
  .impact-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 30px;
  }
  
  .impact-icon {
    width: 32px;
    height: 32px;
  }
  
  .impact-icon.positive {
    color: #33ff66;
    filter: drop-shadow(0 0 10px rgba(51, 255, 102, 0.5));
  }
  
  .impact-icon.negative {
    color: #ff3366;
    filter: drop-shadow(0 0 10px rgba(255, 51, 102, 0.5));
  }
  
  .impact-icon.header-icon {
    color: #ffd700;
    filter: drop-shadow(0 0 10px rgba(255, 215, 0, 0.5));
  }
  
  .impact-title {
    color: #ffd700;
    font-family: 'Orbitron', sans-serif;
    font-size: 1.5rem;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
  }
  
  .impact-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
  }
  
  .impact-amount {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Share Tech Mono', monospace;
    font-size: 2rem;
  }
  
  .impact-amount svg {
    color: #8f96ff;
  }
  
  .impact-amount span.positive {
    color: #33ff66;
    text-shadow: 0 0 10px rgba(51, 255, 102, 0.5);
  }
  
  .impact-amount span.negative {
    color: #ff3366;
    text-shadow: 0 0 10px rgba(255, 51, 102, 0.5);
  }
  
  .impact-description,
  .impact-headline {
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.4;
    margin: 0;
    font-family: 'Share Tech Mono', monospace;
  }
  
  .impact-button {
    width: 100%;
    padding: 12px;
    margin-top: 20px;
    border: none;
    border-radius: 6px;
    background: linear-gradient(135deg, #ffd700, #ffb700);
    color: white;
    font-family: 'Share Tech Mono', monospace;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .impact-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  }
  
  @keyframes impactSlideIn {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }