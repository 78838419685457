.bottom-bar {
  height: 60px;
  width: 100%;
  background-color: #1c1c2a;
  color: rgba(108, 116, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 0 20px;
  font-family: 'Share Tech Mono', monospace;
}

.round-indicator {
  color: #8f96ff;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(108, 116, 255, 0.3);
} 