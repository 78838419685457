.round-resolution {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 20px;
}

.round-resolution__content {
  position: relative;
  width: 90%;
  max-width: 800px;
  background: #f4e4bc;
  border: none;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
  overflow: visible;
  padding: 40px;
  margin: auto;
  clip-path: polygon(
    /* Top edge - just one tear */
    0% 0%,
    48% 0%,
    50% 2%,
    52% 0%,
    100% 0%,
    
    /* Right edge - small tear */
    100% 40%,
    98% 42%,
    100% 44%,
    100% 100%,
    
    /* Bottom edge - one tear */
    100% 100%,
    75% 100%,
    73% 98%,
    71% 100%,
    0% 100%,
    
    /* Left edge - small tear */
    0% 70%,
    2% 72%,
    0% 74%,
    0% 0%
  );
}

.round-resolution__page {
  position: relative;
  height: 100%;
  font-family: 'Courier New', monospace;
  color: #2c1810;
  line-height: 1.6;
  background: linear-gradient(to right, transparent 0%, rgba(0,0,0,0.03) 50%, transparent 100%);
}

.round-resolution__date {
  text-align: right;
  font-style: italic;
  margin-bottom: 30px;
  font-size: 0.9rem;
}

.round-resolution__entry {
  padding: 0 20px;
}

.round-resolution__day {
  font-size: 1.2rem;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(44, 24, 16, 0.2);
  padding-bottom: 10px;
}

.round-resolution__paragraph {
  margin-bottom: 20px;
  text-align: justify;
  font-size: 1.1rem;
}

.round-resolution__number {
  font-weight: bold;
  font-size: 1.2em;
  color: #2c1810;
}

.round-resolution__status {
  font-style: italic;
  text-decoration: underline;
  color: #2c1810;
}

.round-resolution__weather {
  margin-top: 40px;
  font-style: italic;
  padding: 20px;
  border-top: 1px solid rgba(44, 24, 16, 0.2);
  border-bottom: 1px solid rgba(44, 24, 16, 0.2);
}

.round-resolution__signature {
  margin-top: 40px;
  text-align: right;
  font-style: italic;
}

.round-resolution__seal {
  margin-top: 10px;
  font-family: 'Rubik Distressed', cursive;
  font-size: 1.2rem;
  color: #2c1810;
  opacity: 0.5;
}

/* Add multiple coffee stains with different opacities and sizes */
.round-resolution__content::after {
  content: '';
  position: absolute;
  top: 20px;
  right: 40px;
  width: 80px;
  height: 80px;
  background: radial-gradient(
    circle at center,
    rgba(101, 67, 33, 0.2) 0%,
    rgba(101, 67, 33, 0.15) 40%,
    rgba(101, 67, 33, 0.1) 60%,
    transparent 80%
  );
  border-radius: 50%;
  transform: rotate(-15deg) scale(1.2, 1);
}

/* Add a second, overlapping coffee stain */
.round-resolution__content::before {
  content: '';
  position: absolute;
  top: 30px;
  right: 50px;
  width: 60px;
  height: 60px;
  background: radial-gradient(
    circle at center,
    rgba(101, 67, 33, 0.15) 0%,
    rgba(101, 67, 33, 0.1) 50%,
    transparent 80%
  );
  border-radius: 50%;
  transform: rotate(20deg) scale(1.1, 0.9);
}

/* Add a ring stain effect */
.round-resolution__coffee-ring {
  position: absolute;
  top: 25px;
  right: 45px;
  width: 70px;
  height: 70px;
  border: 2px solid rgba(101, 67, 33, 0.1);
  border-radius: 50%;
  transform: rotate(-5deg);
}

/* Add an SVG filter for paper texture */
.round-resolution::before {
  content: '';
  position: fixed;
  pointer-events: none;
  opacity: 0;
}

/* Add this SVG to your HTML */
@supports (filter: url('#paper-texture')) {
  .round-resolution::before {
    content: '<svg style="display:none"><filter id="paper-texture"><feTurbulence type="fractalNoise" baseFrequency="0.9" numOctaves="5"/><feDisplacementMap in="SourceGraphic" scale="5"/></filter></svg>';
  }
}

.round-resolution__close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  height: 30px;
  border: none;
  background: none;
  color: #2c1810;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.round-resolution__close:hover {
  background: rgba(44, 24, 16, 0.1);
}

.round-resolution__intro {
  font-size: 1.1rem;
  margin-bottom: 20px;
  font-style: italic;
}

.round-resolution__assignments {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.round-resolution__assignments li {
  font-family: 'Courier New', monospace;
  font-size: 1.1rem;
  padding: 12px 15px;
  background: rgba(27, 59, 80, 0.03);
  border: 1px solid rgba(44, 24, 16, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.round-resolution__assignments li::before {
  content: '•';
  color: #2c1810;
  margin-right: 5px;
}

.round-resolution__result {
  margin-left: auto;
  color: #2c1810;
  font-weight: bold;
}

.round-resolution__narrative {
  font-style: italic;
  color: #2c1810;
  margin-bottom: 25px;
  line-height: 1.6;
  font-size: 1rem;
  text-align: justify;
  border-left: 3px solid rgba(44, 24, 16, 0.2);
  padding-left: 15px;
}

.round-resolution__closing {
  font-style: italic;
  color: #2c1810;
  margin-top: 25px;
  line-height: 1.6;
  font-size: 1rem;
  text-align: justify;
  border-left: 3px solid rgba(44, 24, 16, 0.2);
  padding-left: 15px;
}

/* Update responsive styles to maintain torn effect */
@media (max-width: 768px) {
  .round-resolution__content {
    padding: 30px;
    /* Even simpler clip-path for mobile */
    clip-path: polygon(
      0% 0%,
      48% 0%,
      50% 1%,
      52% 0%,
      100% 0%,
      100% 100%,
      75% 100%,
      73% 99%,
      71% 100%,
      0% 100%
    );
  }

  .round-resolution__content::after {
    width: 60px;
    height: 60px;
    top: 15px;
    right: 30px;
  }

  .round-resolution__content::before {
    width: 45px;
    height: 45px;
    top: 20px;
    right: 35px;
  }

  .round-resolution__coffee-ring {
    width: 50px;
    height: 50px;
    top: 18px;
    right: 32px;
  }

  .round-resolution__narrative,
  .round-resolution__closing {
    font-size: 0.9rem;
    padding-left: 10px;
  }

  .round-resolution__entry {
    padding: 0 10px;
  }

  .round-resolution__paragraph {
    font-size: 1rem;
  }

  .round-resolution__day {
    font-size: 1.1rem;
  }

  .round-resolution__assignments li {
    font-size: 0.9rem;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .round-resolution__result {
    margin-left: 20px;
  }
} 