.play-area {
  flex: 1;
  min-width: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 2vh;
  background-color: #1c1c2a;
  overflow: visible;
  position: relative;
}

.cards-container {
  display: flex;
  gap: min(1.2vw, 14px);
  padding: min(1vw, 16px);
  perspective: 1000px;
  max-width: 100%;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: visible;
  position: relative;
  height: min-content;
  padding-top: 40px;
  padding-bottom: 10px;
}

.cards-container::-webkit-scrollbar {
  display: none;
}

.card-wrapper {
  flex: 0 0 auto;
  transition: transform 0.3s ease;
  position: relative;
  height: min-content;
}

.card-wrapper:hover {
  transform: translateY(-1vh);
  z-index: 10;
}

/* Mobile styles */
@media screen and (max-width: 1680px) {
  .cards-container {
    gap: 12px;
  }
  
  .play-area {
    padding: 20px 1.5vh;
  }
}

@media screen and (max-width: 1200px) {
  .cards-container {
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .cards-container {
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding: 10px;
  }
}

.button-container {
  position: relative;
  z-index: 100;
  margin-top: 10px;
}

.round-indicator {
  color: #8f96ff;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(108, 116, 255, 0.3);
  margin-bottom: 30px;
}

/* Add styles for the round info container */
.round-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 30px;
}

/* Add new styles for the choices indicator */
.choices-indicator {
  color: #33ff66;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1rem;
  padding: 8px 16px;
  background: rgba(51, 255, 102, 0.1);
  border: 1px solid rgba(51, 255, 102, 0.3);
  border-radius: 6px;
  text-shadow: 0 0 10px rgba(51, 255, 102, 0.3);
  display: flex;
  align-items: center;
  gap: 8px;
}

.choices-counter {
  color: #fff;
  background: rgba(51, 255, 102, 0.2);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 4px;
  border: 1px solid rgba(51, 255, 102, 0.4);
  box-shadow: 0 0 10px rgba(51, 255, 102, 0.2);
} 