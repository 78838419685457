.antarctica-view {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.antarctica-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  z-index: 0;
}

.antarctica-content {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

/* Make all direct children appear above the background */
.antarctica-view > *:not(.antarctica-background) {
  position: relative;
  z-index: 1;
} 