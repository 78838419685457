.game-button {
  position: relative;
  padding: 15px 40px;
  background: linear-gradient(
    145deg,
    #232336 0%,
    #2a2a40 50%,
    #2d2d45 100%
  );
  border: none;
  border-radius: 6px;
  color: #8f96ff;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 
    0 0 30px rgba(20, 20, 30, 0.5),
    inset 0 0 15px rgba(108, 116, 255, 0.15);
}

.button-content {
  position: relative;
  z-index: 2;
}

.button-border {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgba(108, 116, 255, 0.5);
  border-radius: 6px;
}

.button-glow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(
    circle at center,
    rgba(108, 116, 255, 0.2) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
}

.game-button:hover {
  transform: translateY(-2px);
  color: #ffffff;
  text-shadow: 0 0 8px rgba(108, 116, 255, 0.5);
}

.game-button:hover .button-glow {
  opacity: 1;
}

.game-button:active {
  transform: translateY(1px);
}

.game-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
}

.game-button.disabled:hover {
  transform: none;
  color: #8f96ff;
  text-shadow: none;
}

.game-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    transparent 50%,
    rgba(108, 116, 255, 0.025) 50%
  );
  background-size: 100% 4px;
  pointer-events: none;
  opacity: 0.5;
  z-index: 1;
} 