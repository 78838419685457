.morale-tracker {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0;
}

.morale-tracker__icon {
  width: 56px;
  height: 56px;
  object-fit: contain;
  filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.3));
  position: relative;
  z-index: 2;
  margin-right: -28px;
}

.morale-tracker__content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 16px 4px 32px;
  font-family: 'Rubik Distressed', cursive;
  position: relative;
  background: rgba(255, 255, 255, 0.9);
  height: 30px;
  min-width: 80px;
  clip-path: polygon(
    0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
    80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
    78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
    3% 100%, 0 85%
  );
}

.morale-tracker__count {
  font-size: 1.4rem;
  color: #1b3b50;
  position: relative;
  z-index: 1;
  text-shadow: 
    -1px -1px 0 rgba(255, 255, 255, 0.7),
    1px -1px 0 rgba(255, 255, 255, 0.7),
    -1px 1px 0 rgba(255, 255, 255, 0.7),
    1px 1px 0 rgba(255, 255, 255, 0.7);
}

/* Frost crack animation */
@keyframes frostCrack {
  0%, 100% { 
    clip-path: polygon(
      0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
      80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
      78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
      3% 100%, 0 85%
    );
  }
  50% { 
    clip-path: polygon(
      0 13%, 4% 0, 18% 0, 20% 3%, 42% 3%, 44% 0, 58% 0, 60% 3%,
      82% 3%, 84% 0, 96% 0, 100% 13%, 100% 87%, 96% 100%, 82% 100%,
      80% 97%, 58% 97%, 56% 100%, 42% 100%, 40% 97%, 18% 97%, 16% 100%,
      4% 100%, 0 87%
    );
  }
}

.morale-tracker__content {
  animation: frostCrack 4s infinite ease-in-out;
}

/* Responsive styles */
@media screen and (max-width: 1440px) {
  .morale-tracker__icon {
    width: 48px;
    height: 48px;
    margin-right: -24px;
  }

  .morale-tracker__content {
    height: 32px;
    padding: 5px 14px 5px 28px;
  }
}

@media screen and (max-width: 1200px) {
  .morale-tracker__icon {
    width: 42px;
    height: 42px;
    margin-right: -21px;
  }

  .morale-tracker__content {
    height: 28px;
    padding: 4px 12px 4px 24px;
  }
}

@media screen and (max-width: 1024px) {
  .morale-tracker__icon {
    width: 38px;
    height: 38px;
    margin-right: -19px;
  }

  .morale-tracker__content {
    height: 24px;
    padding: 4px 10px 4px 20px;
  }
}

@media screen and (max-width: 768px) {
  .morale-tracker__icon {
    width: 34px;
    height: 34px;
    margin-right: -17px;
  }

  .morale-tracker__content {
    height: 20px;
    padding: 3px 8px 3px 16px;
  }
}

@media screen and (max-width: 480px) {
  .morale-tracker__icon {
    width: 30px;
    height: 30px;
    margin-right: -15px;
  }

  .morale-tracker__content {
    height: 16px;
    padding: 3px 6px 3px 12px;
  }
} 