.character {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 8px;
}

.character__frame {
  position: relative;
  border-radius: 50%;
  border: 2px solid rgba(147, 186, 217, 0.3);
  transition: all 0.3s ease;
}

.character__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

/* Size variants */
.character--small .character__frame {
  width: 40px;
  height: 40px;
}

.character--medium .character__frame {
  width: 80px;
  height: 80px;
}

.character--large .character__frame {
  width: 120px;
  height: 120px;
}

/* Hover effects */
.character:hover .character__frame {
  border-color: rgba(147, 186, 217, 0.8);
  box-shadow: 
    0 0 10px rgba(147, 186, 217, 0.2),
    0 0 20px rgba(147, 186, 217, 0.1);
}

.character:hover .character__image {
  transform: scale(1.1);
}

/* Add these styles to your existing Characters.css */

.character--has-news .character__frame {
  border-color: #FF9F1C;
}

.character__news-indicator {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  background-color: #1b3b50;
  border: 1px solid #93BAD9;
  z-index: 2;
  transform: rotate(45deg);
  box-shadow: 
    inset 0 0 0 1px rgba(147, 186, 217, 0.3),
    0 0 10px rgba(147, 186, 217, 0.4);
  animation: crystalPulse 2s infinite ease-in-out;
}

.character__news-indicator::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  background: #93BAD9;
  transform: rotate(0deg);
  animation: crystalGlow 2s infinite ease-in-out;
}

@keyframes crystalPulse {
  0% {
    transform: rotate(45deg) scale(1);
  }
  50% {
    transform: rotate(45deg) scale(1.2);
  }
  100% {
    transform: rotate(45deg) scale(1);
  }
}

@keyframes crystalGlow {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

/* Add a new pulsing overlay */
.character--has-news::before {
  content: '';
  position: absolute;
  inset: -5px;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 159, 28, 0.4) 0%,
    transparent 70%
  );
  animation: newsGlow 2s infinite ease-in-out;
  pointer-events: none;
  z-index: 1;
}

@keyframes newsGlow {
  0% {
    transform: scale(0.95);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.2;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.4;
  }
}

/* Enhanced hover effect for characters with news */
.character--has-news:hover .character__image {
  transform: scale(1.15);
  filter: brightness(120%);
}

.character--has-news:hover .character__frame {
  border-color: #FF9F1C;
  box-shadow: 
    0 0 15px rgba(255, 159, 28, 0.4),
    0 0 30px rgba(255, 159, 28, 0.2);
}

/* Size-specific adjustments */
.character--medium.character--has-news::before {
  inset: -15px;
}

.character--large.character--has-news::before {
  inset: -20px;
}

/* Size-specific adjustments for the indicator */
.character--medium .character__news-indicator {
  width: 20px;
  height: 20px;
  top: -8px;
  right: -8px;
}

.character--large .character__news-indicator {
  width: 24px;
  height: 24px;
  top: -10px;
  right: -10px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(227, 181, 5, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(227, 181, 5, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(227, 181, 5, 0);
  }
}

/* Add a subtle glow effect when hovering over a character with news */
.character--has-news:hover .character__frame {
  box-shadow: 
    0 0 15px rgba(227, 181, 5, 0.3),
    0 0 30px rgba(227, 181, 5, 0.2);
}

/* Add to existing Characters.css */
.antarctica-left-bar__portraits {
  width: 60px;
  padding: 10px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
} 