.character-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.character-selector__portraits {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.character-portrait {
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.character-portrait__frame {
  width: 120px;
  height: 120px;
  position: relative;
  overflow: visible;
  border-radius: 50%;
  border: 2px solid rgba(147, 186, 217, 0.3);
  transition: all 0.3s ease;
}

.character-portrait__frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(80%) brightness(80%);
  transition: all 0.3s ease;
}

.character-portrait__name {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  white-space: nowrap;
  opacity: 0;
  transition: all 0.3s ease;
}

.character-portrait:hover .character-portrait__frame,
.character-portrait.selected .character-portrait__frame {
  border-color: rgba(147, 186, 217, 0.8);
  box-shadow: 
    0 0 20px rgba(147, 186, 217, 0.2),
    0 0 40px rgba(147, 186, 217, 0.1);
}

.character-portrait:hover .character-portrait__frame img,
.character-portrait.selected .character-portrait__frame img {
  filter: grayscale(0%) brightness(100%);
  transform: scale(1.1);
}

.character-portrait:hover .character-portrait__name,
.character-portrait.selected .character-portrait__name {
  opacity: 1;
  bottom: -30px;
}

.character-details {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.character-details__content {
  animation: fadeIn 0.3s ease;
}

.character-details__content h3 {
  font-family: 'Rubik Distressed', cursive;
  color: #93BAD9;
  font-size: 1.4rem;
  margin: 0 0 10px;
  text-shadow: 0 0 20px rgba(147, 186, 217, 0.3);
}

.character-details__content p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  max-width: 600px;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Add subtle frost effect on hover */
.character-portrait::after {
  content: '';
  position: absolute;
  inset: -20px;
  background: radial-gradient(
    circle at center,
    rgba(147, 186, 217, 0.1) 0%,
    transparent 70%
  );
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.character-portrait:hover::after {
  opacity: 1;
}

.character-portrait__selected-indicator {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 28px;
  height: 28px;
  background: #93BAD9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b3b50;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #1b3b50;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  animation: scaleIn 0.2s ease;
  z-index: 2;
}

@keyframes scaleIn {
  from { transform: scale(0); }
  to { transform: scale(1); }
} 