.editor-content {
  flex: 1;
  height: calc(100vh - 72px);
  overflow: hidden;
  display: flex;
  background: #f9fafb;
}

/* Left Column */
.left-column {
  width: 260px;
  min-width: 260px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.simulation-header {
  display: flex;
  flex-direction: column;
}

.title-display {
  position: relative;
  cursor: text;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 8px;
}

.title-display:hover {
  background: rgba(0, 0, 0, 0.03);
}

.title-display h1 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.description-display {
  position: relative;
  cursor: text;
  padding: 8px;
  border-radius: 6px;
}

.description-display:hover {
  background: rgba(0, 0, 0, 0.03);
}

.description-display p {
  font-size: 0.9rem;
  color: #4b5563;
  margin: 0;
  line-height: 1.5;
}

.edit-icon {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  color: #059669;
  transition: opacity 0.2s;
}

.title-display:hover .edit-icon,
.description-display:hover .edit-icon {
  opacity: 1;
}

.title-edit {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 8px;
  border: 2px solid #059669;
  border-radius: 6px;
  background: white;
  margin: 0;
}

.description-edit {
  width: 100%;
  font-size: 0.9rem;
  padding: 8px;
  border: 2px solid #059669;
  border-radius: 6px;
  background: white;
  margin: 8px 0 0 0;
  min-height: 80px;
  resize: vertical;
}

.title-edit:focus,
.description-edit:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(5, 150, 105, 0.1);
}

.settings-intro {
  background: #f0fdf4;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid #d1fae5;
  margin-top: auto;
}

.intro-icon {
  color: #059669;
  margin-bottom: 16px;
}

.intro-content h2 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #111827;
  margin: 0 0 12px 0;
}

.intro-content p {
  font-size: 0.9rem;
  color: #4b5563;
  margin: 0 0 16px 0;
  line-height: 1.5;
}

.intro-content ul {
  font-size: 0.9rem;
  color: #4b5563;
  margin: 0;
  padding-left: 20px;
}

.intro-content li {
  margin-bottom: 8px;
}

/* Right Column */
.right-column {
  flex: 1;
  padding: 24px;
  min-width: 0;
}

.content-card {
  background: white;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.simulation-meta {
  display: flex;
  gap: 12px;
  margin-top: 24px;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
}

.meta-item {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: #f8fafc;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  min-width: 0;
}

.meta-icon {
  color: #64748b;
  flex-shrink: 0;
}

.meta-input {
  border: none;
  background: transparent;
  font-size: 0.9rem;
  color: #334155;
  width: 100%;
  min-width: 0;
  padding: 0;
}

.meta-input:focus {
  outline: none;
}

.duration-input {
  display: flex;
  align-items: center;
  gap: 4px;
}

.duration-unit {
  color: #64748b;
  font-size: 0.9rem;
}

.meta-input[type="number"] {
  -moz-appearance: textfield;
}

.meta-input[type="number"]::-webkit-outer-spin-button,
.meta-input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.level-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  padding: 0 8px;
}

.level-label {
  height: 40px;
  padding: 0 16px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #94a3b8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.2s ease;
  user-select: none;
  line-height: 1.2;
}

.level-label:hover {
  border-color: #64748b;
  background: #f1f5f9;
  color: #475569;
}

.level-label.selected {
  background: #f1f5f9;
  border-color: #475569;
  color: #1e293b;
}

/* Remove the previous color-specific styles */
.level-label[data-id="new-joiners"],
.level-label[data-id="individual-contributors"],
.level-label[data-id="managers"],
.level-label[data-id="executives"] {
  --label-color: #475569;
  --label-bg: #f1f5f9;
}

/* Remove the previous select styles */
.audience-select select,
.audience-select select option {
  display: none;
}

.content-grid {
  display: flex;
  height: 100%;
}

.content-left {
  width: 20%;
  padding: 24px;
  color: #4b5563;
  font-size: 0.9rem;
  line-height: 1.5;
}

.content-left h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #111827;
  margin: 0 0 12px 0;
}

.content-description {
  color: #6b7280;
  margin-bottom: 24px;
}

.content-meta {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 24px;
  padding: 0 8px;
}

.meta-item {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  min-width: 0;
}

.content-divider {
  width: 1px;
  height: 100%;
  background: #e5e7eb;
}

.content-right {
  width: 80%;
  padding: 24px;
  color: #4b5563;
  font-size: 0.9rem;
  line-height: 1.5;
}

/* Add this new class for the editable content */
.editable-content {
  margin-bottom: 24px;
}

.section-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #111827;
  margin: 24px 0 4px 8px;
}

.section-description {
  font-size: 0.8rem;
  color: #6b7280;
  margin: 0 0 12px 8px;
}

.details-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 8px;
  margin-top: 12px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.detail-item label {
  font-size: 0.8rem;
  font-weight: 500;
  color: #64748b;
}

.detail-input {
  width: 100%;
  padding: 8px 12px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #334155;
  transition: all 0.2s ease;
}

.detail-input:focus {
  outline: none;
  border-color: #64748b;
  background: #fff;
}

.detail-input::placeholder {
  color: #94a3b8;
}

/* Add new styles */
.input-group {
  padding: 0 8px;
  margin-bottom: 8px;
}

.input-group label {
  display: block;
  font-size: 0.8rem;
  font-weight: 500;
  color: #64748b;
  margin-bottom: 4px;
}

.detail-input,
.category-input {
  width: 100%;
  padding: 0 16px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #334155;
  height: 40px;
}

.category-input {
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%2364748b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
}

.competencies-input {
  padding: 0 8px;
}

.competencies-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  padding: 0;
}

/* Remove the old category-select styles */
.category-select {
  display: none;
}

/* Update section spacing */
.section-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #111827;
  margin: 24px 0 4px 8px;
}

.section-description {
  font-size: 0.8rem;
  color: #6b7280;
  margin: 0 0 12px 8px;
}

/* Add this to ensure inputs don't have browser default padding */
.detail-input {
  box-sizing: border-box;
}

/* Update/Add these styles */
.competencies-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.competencies-select {
  flex: 1;
}

.competencies__control {
  min-height: 40px !important;
  height: 100% !important;
  background: #f8fafc !important;
}

.competencies__value-container {
  padding: 2px 8px !important;
}

.competencies__multi-value {
  background: white !important;
  border: 1px solid #e2e8f0 !important;
  border-radius: 4px !important;
}

.competencies__multi-value__label {
  font-size: 0.8rem !important;
  color: #334155 !important;
  padding: 4px !important;
}

.competencies__multi-value__remove {
  padding: 0 4px !important;
}

/* Remove old competencies styles */
.competencies-container,
.competencies-tags,
.competency-tag {
  display: none;
}

/* Add these new styles */
.kpis-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 8px 16px;
}

.add-kpi-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  color: #64748b;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.kpis-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.kpi-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
}

.kpi-name-input {
  width: 200px;
  height: 40px;
  padding: 0 16px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #334155;
  transition: all 0.2s;
}

.kpi-description-input {
  flex: 1;
  height: 40px;
  padding: 0 16px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #334155;
  transition: all 0.2s;
}

.kpi-value-input {
  width: 20px;
  height: 40px;
  padding: 0 16px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 700;
  color: #334155;
  transition: all 0.2s;
  text-align: center;
}

.kpi-name-input:focus,
.kpi-description-input:focus,
.kpi-value-input:focus {
  outline: none;
  border-color: #64748b;
  background: #fff;
}

.delete-kpi-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: #94a3b8;
  cursor: pointer;
  transition: all 0.2s;
}

.delete-kpi-button:hover {
  background: #fee2e2;
  color: #ef4444;
}

/* Remove spinner buttons from number input */
.kpi-value-input::-webkit-outer-spin-button,
.kpi-value-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.kpi-value-input {
  -moz-appearance: textfield;
}