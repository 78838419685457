.ice-button {
  position: relative;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.ice-button:hover {
  transform: translateY(-2px);
}

.ice-button__content {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 30px;
  font-family: 'Rubik Distressed', cursive;
  font-size: 1.2rem;
  color: #1b3b50;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 1;
  clip-path: polygon(
    0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
    80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
    78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
    3% 100%, 0 85%
  );
  text-shadow: 
    -1px -1px 0 rgba(255, 255, 255, 0.7),
    1px -1px 0 rgba(255, 255, 255, 0.7),
    -1px 1px 0 rgba(255, 255, 255, 0.7),
    1px 1px 0 rgba(255, 255, 255, 0.7);
}

.ice-button__icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.3));
}

.ice-button__frost {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  clip-path: polygon(
    0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
    80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
    78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
    3% 100%, 0 85%
  );
  z-index: 2;
  pointer-events: none;
}

.ice-button:hover .ice-button__content {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 
    0 0 30px rgba(255, 255, 255, 0.4),
    0 0 60px rgba(147, 186, 217, 0.4);
}

@keyframes frostShine {
  0% { opacity: 0.4; }
  50% { opacity: 0.6; }
  100% { opacity: 0.4; }
}

.ice-button:hover .ice-button__frost {
  animation: frostShine 2s infinite ease-in-out;
}

.ice-button:hover .ice-button__icon {
  filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.5));
} 