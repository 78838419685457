.strategy-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  height: 100%;
  justify-content: center;
  margin-top: -60px;
}

.strategy-choice__title {
  font-family: 'Rubik Distressed', cursive;
  color: #ffffff;
  font-size: 1.6rem;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  text-align: center;
  margin: 0;
}

.strategy-choice__cards {
  display: flex;
  gap: 60px;
  justify-content: center;
}

.strategy-card {
  width: 280px;
  height: 340px;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
  transform-style: preserve-3d;
  perspective: 1000px;
  opacity: 0.6;
}

.strategy-card:hover {
  opacity: 0.8;
  transform: translateY(-10px) rotateY(5deg);
}

.strategy-card:hover {
  animation: none;
}

.strategy-card__content {
  position: relative;
  height: 100%;
  background: rgba(200, 220, 255, 0.2);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  clip-path: polygon(
    0 15px, 15px 0,
    calc(100% - 15px) 0, 100% 15px,
    100% calc(100% - 15px), calc(100% - 15px) 100%,
    15px 100%, 0 calc(100% - 15px)
  );
  z-index: 1;
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding: 30px;
}

.strategy-card__content::before {
  content: '';
  position: absolute;
  inset: 12px;
  background: transparent;
  clip-path: polygon(
    0 15px, 15px 0,
    calc(100% - 15px) 0, 100% 15px,
    100% calc(100% - 15px), calc(100% - 15px) 100%,
    15px 100%, 0 calc(100% - 15px)
  );
  box-shadow: 
    inset 0 0 0 6px rgba(147, 186, 217, 0.6),
    inset 0 0 0 8px rgba(255, 255, 255, 0.3);
  pointer-events: none;
  z-index: 1;
}

.strategy-card__header {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  height: 100%;
}

.strategy-card__icon {
  width: 160px;
  height: 160px;
  object-fit: contain;
  margin-top: auto;
}

.strategy-card__title {
  font-family: 'Rubik Distressed', cursive;
  color: #1b3b50;
  font-size: 2rem;
  margin: auto 0 0 0;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);
}

.strategy-card:hover .strategy-card__content {
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.2),
    0 0 30px rgba(147, 186, 217, 0.3);
}

.strategy-card__frost {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  clip-path: polygon(
    0 15px, 15px 0,
    calc(100% - 15px) 0, 100% 15px,
    100% calc(100% - 15px), calc(100% - 15px) 100%,
    15px 100%, 0 calc(100% - 15px)
  );
  z-index: 2;
  pointer-events: none;
}

/* Add card tilt animation on hover */
@keyframes tiltRight {
  0% { transform: rotate3d(0, 1, 0, 0deg); }
  50% { transform: rotate3d(0, 1, 0, 5deg); }
  100% { transform: rotate3d(0, 1, 0, 0deg); }
}

.strategy-card:hover {
  animation: tiltRight 5s infinite ease-in-out;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .strategy-choice__cards {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .strategy-card {
    width: 240px;
    height: 300px;
  }

  .strategy-card__icon {
    width: 120px;
    height: 120px;
  }

  .strategy-card__title {
    font-size: 1.6rem;
    letter-spacing: 3px;
  }
} 

/* Selection effects */
.strategy-card--selected {
  opacity: 1;
}

.strategy-card--selected:hover {
  opacity: 1;
  transform: none;
}

.strategy-card--selected .strategy-card__content {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 
    0 0 30px rgba(255, 255, 255, 0.4),
    0 0 60px rgba(147, 186, 217, 0.4),
    inset 0 0 20px rgba(255, 255, 255, 0.4);
}

.strategy-card--selected .strategy-card__icon {
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8));
  animation: iconPulse 2s infinite ease-in-out;
}

.strategy-card--selected .strategy-card__title {
  text-shadow: 
    0 0 20px rgba(255, 255, 255, 0.8),
    0 0 40px rgba(147, 186, 217, 0.6);
  animation: textGlow 2s infinite ease-in-out;
}

/* Ice crack lines for selected card */
.strategy-card--selected .strategy-card__content::after {
  content: '';
  position: absolute;
  inset: 0;
  background: 
    linear-gradient(45deg, transparent 48%, rgba(255, 255, 255, 0.8) 50%, transparent 52%),
    linear-gradient(-45deg, transparent 48%, rgba(255, 255, 255, 0.8) 50%, transparent 52%);
  background-size: 200% 200%;
  animation: crackMove 3s infinite linear;
  pointer-events: none;
}

.strategy-card--unselected {
  opacity: 0.7;
}

.strategy-card--unselected .strategy-card__content {
  background: rgba(200, 220, 255, 0.2);
  backdrop-filter: blur(4px);
}

.strategy-card--unselected .strategy-card__content::after {
  content: '';
  position: absolute;
  inset: 0;
  background: 
    linear-gradient(45deg, transparent 30%, rgba(255, 255, 255, 0.4) 40%, transparent 50%),
    repeating-linear-gradient(-45deg, 
      rgba(255, 255, 255, 0.1) 0px,
      rgba(255, 255, 255, 0.1) 2px,
      transparent 2px,
      transparent 4px
    );
  animation: frostOver 1s forwards;
  pointer-events: none;
}

.strategy-card--unselected .strategy-card__title {
  color: rgba(27, 59, 80, 0.6);
}

@keyframes frostOver {
  0% {
    opacity: 0;
    background-position: -100% 0, 0 0;
  }
  100% {
    opacity: 1;
    background-position: 200% 0, 0 0;
  }
}

@keyframes pulseGlow {
  0%, 100% {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.6));
  }
  50% {
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.8));
  }
}

@keyframes iceCrack {
  0%, 100% { 
    box-shadow: 
      0 0 30px rgba(255, 255, 255, 0.4),
      0 0 60px rgba(147, 186, 217, 0.3),
      inset 0 0 20px rgba(255, 255, 255, 0.4);
  }
  50% { 
    box-shadow: 
      0 0 40px rgba(255, 255, 255, 0.6),
      0 0 80px rgba(147, 186, 217, 0.4),
      inset 0 0 30px rgba(255, 255, 255, 0.6);
  }
}

@keyframes pulseAura {
  0%, 100% { 
    opacity: 0.5;
    transform: scale(1);
  }
  50% { 
    opacity: 0.8;
    transform: scale(1.1);
  }
}

@keyframes iconPulse {
  0%, 100% { 
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.6))
           brightness(1);
    transform: scale(1);
  }
  50% { 
    filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.8))
           brightness(1.2);
    transform: scale(1.1);
  }
}

@keyframes textGlow {
  0%, 100% {
    text-shadow: 
      0 0 20px rgba(255, 255, 255, 0.8),
      0 0 40px rgba(147, 186, 217, 0.6);
  }
  50% {
    text-shadow: 
      0 0 30px rgba(255, 255, 255, 1),
      0 0 60px rgba(147, 186, 217, 0.8);
  }
}

@keyframes crackMove {
  0% { background-position: -200% -200%; }
  100% { background-position: 200% 200%; }
} 