.ai-button-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .ai-button-container:hover {
    background: rgba(255, 51, 102, 0.1);
    transform: translateY(-2px);
  }
  
  .ai-button {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 
      0 0 20px rgba(255, 51, 102, 0.3),
      0 0 40px rgba(255, 51, 102, 0.2),
      inset 0 0 10px rgba(255, 51, 102, 0.5);
    animation: floatButton 3s ease-in-out infinite;
  }
  
  .ai-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    transition: all 0.3s ease;
    filter: saturate(1.2) contrast(1.1);
  }
  
  .ai-button-glow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: radial-gradient(circle at center, 
      rgba(255, 51, 102, 0.3) 0%, 
      rgba(153, 51, 255, 0.2) 50%,
      transparent 70%);
    pointer-events: none;
    animation: evilPulse 3s infinite, colorShift 8s infinite;
    mix-blend-mode: screen;
    filter: blur(8px);
  }
  
  .ai-button-ring {
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border: 2px solid rgba(255, 51, 102, 0.3);
    border-radius: 50%;
    animation: rotateEvil 12s linear infinite;
  }
  
  .ai-button-ring::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px solid transparent;
    border-top: 2px solid rgba(255, 51, 102, 0.8);
    border-left: 2px solid rgba(153, 51, 255, 0.8);
    border-radius: 50%;
    animation: rotateEvil 3s linear infinite reverse;
  }
  
  .ai-button-ring::after {
    content: '';
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border: 2px solid transparent;
    border-bottom: 2px solid rgba(255, 51, 102, 0.6);
    border-right: 2px solid rgba(153, 51, 255, 0.6);
    border-radius: 50%;
    animation: rotateEvil 5s linear infinite;
  }
  
  .ai-label {
    font-size: 0.7rem;
    color: #ff3366;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-shadow: 0 0 10px rgba(255, 51, 102, 0.5);
    position: relative;
    transition: all 0.3s ease;
  }
  
  .ai-label::after {
    content: '';
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 1px;
    background: #ff3366;
    transition: all 0.3s ease;
  }
  
  .ai-button-container:hover .ai-label::after {
    width: 100%;
  }
  
  .ai-button-container:hover .ai-image {
    transform: scale(1.1);
    filter: saturate(1.5) contrast(1.2) brightness(1.1);
  }
  
  .ai-button::before {
    content: '';
    position: absolute;
    inset: -2px;
    background: conic-gradient(
      from 0deg,
      transparent,
      rgba(255, 51, 102, 0.8) 45deg,
      rgba(255, 51, 102, 0.3) 180deg,
      transparent 360deg
    );
    animation: rotate 4s linear infinite;
    z-index: -1;
  }
  
  @keyframes evilPulse {
    0% {
      opacity: 0.5;
      transform: scale(1);
    }
    50% {
      opacity: 0.8;
      transform: scale(1.2);
    }
    100% {
      opacity: 0.5;
      transform: scale(1);
    }
  }
  
  @keyframes rotateEvil {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes glitch {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0.2;
      transform: translate(-2px, 2px);
    }
    20% {
      opacity: 0;
    }
    30% {
      opacity: 0.2;
      transform: translate(2px, -2px);
    }
    40% {
      opacity: 0;
    }
    50% {
      opacity: 0.2;
      transform: translate(-1px, 1px);
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  .ai-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 2px,
      rgba(255, 51, 102, 0.1) 2px,
      rgba(255, 51, 102, 0.1) 4px
    );
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .ai-button-container:hover .ai-button::after {
    opacity: 1;
  }
  
  .ai-button-container.active {
    background: rgba(255, 51, 102, 0.1);
    transform: translateY(-2px) scale(1.05);
  }
  
  .ai-button-container.active .ai-button {
    box-shadow: 
      0 0 30px rgba(255, 51, 102, 0.6),
      0 0 50px rgba(255, 51, 102, 0.4),
      inset 0 0 20px rgba(255, 51, 102, 0.8);
  }
  
  .ai-button-container.active .ai-label::after {
    width: 100%;
  }
  
  .ai-button-container.active .ai-image {
    filter: saturate(1.5) contrast(1.2) brightness(1.1);
  }
  
  @keyframes floatButton {
    0% {
      transform: translateY(0);
      box-shadow: 
        0 0 20px rgba(255, 51, 102, 0.3),
        0 0 40px rgba(255, 51, 102, 0.2),
        inset 0 0 10px rgba(255, 51, 102, 0.5);
    }
    50% {
      transform: translateY(-6px);
      box-shadow: 
        0 0 30px rgba(255, 51, 102, 0.4),
        0 0 50px rgba(255, 51, 102, 0.3),
        inset 0 0 15px rgba(255, 51, 102, 0.6);
    }
    100% {
      transform: translateY(0);
      box-shadow: 
        0 0 20px rgba(255, 51, 102, 0.3),
        0 0 40px rgba(255, 51, 102, 0.2),
        inset 0 0 10px rgba(255, 51, 102, 0.5);
    }
  }
  
  @keyframes colorShift {
    0%, 100% { 
      background: radial-gradient(circle at center,
        rgba(255, 51, 102, 0.3) 0%,
        rgba(153, 51, 255, 0.2) 50%,
        transparent 70%
      );
    }
    50% { 
      background: radial-gradient(circle at center,
        rgba(153, 51, 255, 0.3) 0%,
        rgba(255, 51, 102, 0.2) 50%,
        transparent 70%
      );
    }
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }