.editor-side {
    width: 72px;
    min-width: 72px;
    height: calc(100vh - 72px);
    background: white;
    border-right: 1px solid #eee;
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  
  .side-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: none;
    border-radius: 8px;
    background: transparent;
    color: #666;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .side-button:hover {
    background: #f5f5f5;
    color: #333;
  }