.task-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
}

.task-progress__label {
  font-family: 'Rubik Distressed', cursive;
  color: #ffffff;
  font-size: 1.1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 
    0 0 10px rgba(147, 186, 217, 0.5),
    0 2px 4px rgba(0, 0, 0, 0.5);
}

.task-progress__bar-container {
  width: 100%;
  height: 25px;
  position: relative;
  overflow: hidden;
  clip-path: polygon(
    0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
    80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
    78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
    3% 100%, 0 85%
  );
  background: rgba(255, 255, 255, 0.9);
}

.task-progress__bar {
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(147, 186, 217, 0.4) 0%,
    rgba(147, 186, 217, 0.8) 100%
  );
  transition: width 0.05s linear;
  position: relative;
  box-shadow: 
    inset 0 0 20px rgba(147, 186, 217, 0.3),
    0 0 15px rgba(147, 186, 217, 0.4);
}

.task-progress__ticks {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  pointer-events: none;
}

.task-progress__tick {
  width: 2px;
  height: 100%;
  background: rgba(27, 59, 80, 0.2);
}

.task-progress__bar-container::after {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  pointer-events: none;
  z-index: 2;
}

.task-progress__bar-container:hover::after {
  animation: frostShine 2s infinite ease-in-out;
}

@keyframes frostShine {
  0% { opacity: 0.4; }
  50% { opacity: 0.6; }
  100% { opacity: 0.4; }
}

/* Update responsive styles */
@media (max-width: 1024px) {
  .task-progress {
    max-width: 90%;
  }

  .task-progress__label {
    font-size: 0.9rem;
  }

  .task-progress__bar-container {
    height: 20px;
  }
}

@media (max-width: 768px) {
  .task-progress {
    max-width: 95%;
  }

  .task-progress__label {
    font-size: 0.8rem;
  }
} 