.bought-items {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
}

.bought-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.bought-item:hover {
  background: rgba(108, 116, 255, 0.1);
}

.bought-item__icon-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(108, 116, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  position: relative;
}

.bought-item__icon {
  color: #8f96ff;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}

.bought-item:hover .bought-item__icon {
  color: #fff;
  filter: drop-shadow(0 0 10px rgba(108, 116, 255, 0.5));
}

.bought-item__label {
  font-size: 0.65rem;
  color: #8f96ff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  text-align: center;
}

.bought-item.active {
  background: rgba(108, 116, 255, 0.1);
}

.bought-item.active .bought-item__icon-circle {
  background: rgba(108, 116, 255, 0.2);
  box-shadow: 0 0 15px rgba(108, 116, 255, 0.3);
}

.bought-item.active .bought-item__icon {
  color: #fff;
  filter: drop-shadow(0 0 10px rgba(108, 116, 255, 0.5));
}

.bought-item.active .bought-item__label {
  color: #fff;
}

.bought-item.one-time {
  border: 1px solid rgba(255, 51, 102, 0.2);
  animation: oneTimePulse 2s infinite;
}

.bought-item.one-time:hover {
  border-color: rgba(255, 51, 102, 0.4);
  background: rgba(255, 51, 102, 0.1);
}

.bought-item__one-time-hint {
  font-size: 0.65rem;
  color: #6c74ff;
  opacity: 0.8;
  margin-top: -2px;
}

@keyframes oneTimePulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 51, 102, 0.2);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(255, 51, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 51, 102, 0);
  }
}

.bought-item__uses {
  position: absolute;
  top: -3px;
  right: -3px;
  background: #ff3366;
  color: white;
  font-size: 0.65rem;
  min-width: 16px;
  height: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Share Tech Mono', monospace;
  border: 1px solid #1f1f30;
  box-shadow: 0 0 10px rgba(255, 51, 102, 0.5);
}

.bought-item:hover .bought-item__uses {
  background: #ff1f4f;
  box-shadow: 0 0 15px rgba(255, 51, 102, 0.7);
}

.bought-item.perpetual {
  border: 1px solid rgba(51, 255, 102, 0.2);
  animation: perpetualPulse 2s infinite;
}

.bought-item.perpetual:hover {
  border-color: rgba(51, 255, 102, 0.4);
  background: rgba(51, 255, 102, 0.1);
}

.bought-item__uses.perpetual {
  background: #33ff66;
  color: #1f1f30;
  box-shadow: 0 0 10px rgba(51, 255, 102, 0.5);
}

.bought-item:hover .bought-item__uses.perpetual {
  background: #1fff4f;
  box-shadow: 0 0 15px rgba(51, 255, 102, 0.7);
}

@keyframes perpetualPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 255, 102, 0.2);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(51, 255, 102, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(51, 255, 102, 0);
  }
}

/* Limited use items (like Risk Oracle, System Patch) */
.bought-item.limited {
  border: 1px solid rgba(108, 116, 255, 0.2);
  animation: limitedPulse 2s infinite;
}

.bought-item.limited:hover {
  border-color: rgba(108, 116, 255, 0.4);
  background: rgba(108, 116, 255, 0.1);
}

/* Animation for limited use items */
@keyframes limitedPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(108, 116, 255, 0.2);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(108, 116, 255, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(108, 116, 255, 0);
  }
} 