.guide-overlay {
  position: fixed;
  inset: 0;
  z-index: 100;
  pointer-events: none;
}

.guide-overlay__backdrop-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  pointer-events: auto;
}

.guide-overlay__backdrop-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  pointer-events: auto;
}

.guide-overlay__backdrop-left {
  position: absolute;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  pointer-events: auto;
}

.guide-overlay__backdrop-right {
  position: absolute;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  pointer-events: auto;
}

.guide-overlay__message {
  position: absolute;
  background: rgba(31, 31, 48, 0.95);
  border: 1px solid rgba(255, 51, 102, 0.3);
  border-radius: 8px;
  padding: 12px 36px 12px 12px;
  width: 240px;
  pointer-events: auto;
}

.guide-overlay__close {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 4px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.2s;
}

.guide-overlay__close:hover {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

@keyframes spotlightPulse {
  0% {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5),
                0 0 0 2px rgba(255, 51, 102, 0.3);
  }
  50% {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5),
                0 0 0 4px rgba(255, 51, 102, 0.5);
  }
  100% {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5),
                0 0 0 2px rgba(255, 51, 102, 0.3);
  }
} 