.scenario-card {
  background: linear-gradient(
    145deg,
    #232336 0%,
    #2a2a40 50%,
    #2d2d45 100%
  );
  border-radius: 12px;
  width: min(calc(14vw), 280px);
  height: min(calc(24vw), 420px);
  min-width: 240px;
  min-height: 380px;
  padding: min(0.8vw, 12px);
  position: relative;
  cursor: grab;
  user-select: none;
  border: 1px solid transparent;
  border-image: linear-gradient(
    135deg,
    rgba(108, 116, 255, 0.5) 0%,
    rgba(108, 116, 255, 0.2) 50%,
    rgba(255, 82, 122, 0.5) 100%
  ) 1;
  box-shadow: 
    0 0 30px rgba(20, 20, 30, 0.5),
    inset 0 0 15px rgba(108, 116, 255, 0.15);
  background-image: 
    linear-gradient(135deg, rgba(87, 97, 255, 0.03) 0%, transparent 100%),
    repeating-linear-gradient(
      45deg,
      rgba(87, 97, 255, 0.01) 0px,
      rgba(87, 97, 255, 0.01) 1px,
      transparent 1px,
      transparent 4px
    );
  transition: all 0.3s ease;
  z-index: 1;
}

.scenario-card::before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  border: 1px solid rgba(87, 97, 255, 0.1);
  border-radius: 10px;
  pointer-events: none;
}

.card-number {
  position: absolute;
  top: -0.8vw;
  left: -0.8vw;
  background: linear-gradient(145deg, #232336, #2a2a40);
  color: #8f96ff;
  width: min(2.5vw, 40px);
  height: min(2.5vw, 40px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: min(1.3vw, 20px);
  font-family: 'Orbitron', sans-serif;
  box-shadow: 
    0 0 10px rgba(87, 97, 255, 0.3),
    inset 0 0 5px rgba(87, 97, 255, 0.2);
  border: 1px solid rgba(87, 97, 255, 0.4);
  z-index: 2;
}

.card-number::after {
  content: '';
  position: absolute;
  inset: -3px;
  border-radius: 50%;
  background: conic-gradient(
    from 0deg,
    transparent,
    rgba(87, 97, 255, 0.5),
    transparent,
    transparent
  );
  animation: rotate 4s linear infinite;
}

.card-content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(
    160deg,
    rgba(35, 35, 54, 0.95) 0%,
    rgba(42, 42, 64, 0.9) 100%
  );
  border-radius: 10px;
  padding: min(0.8vw, 12px);
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.3);
}

.card-title {
  height: 60px;
  margin: 0.6vw 0;
  font-size: min(1vw, 14px);
  color: #8f96ff;
  text-align: center;
  font-family: 'Orbitron', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(108, 116, 255, 0.6);
  padding-bottom: 0.6vw;
  border-bottom: 1px solid rgba(87, 97, 255, 0.2);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.4;
  overflow: hidden;
}

.card-title::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(87, 97, 255, 0.5),
    transparent
  );
}

.card-info {
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.4;
  margin: 0.6vw 0;
  font-size: min(1.2vw, 16px);
  font-family: 'Share Tech Mono', monospace;
  flex-grow: 1;
  background: rgba(28, 28, 42, 0.7);
  padding: min(1vw, 14px);
  border-radius: 8px;
  border: 1px solid rgba(108, 116, 255, 0.2);
  position: relative;
  overflow: hidden;
}

.card-info::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at center,
    rgba(87, 97, 255, 0.03) 0%,
    transparent 70%
  );
  animation: pulse 4s ease-out infinite;
}

/* Flag overlays positioned above card */
.flag-overlay {
  position: absolute;
  top: -50px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 1000;
  pointer-events: none;
}

.flag-overlay .flag-icon {
  font-size: 32px;
  width: 32px;
  height: 32px;
  stroke-width: 1.5;
  transform: scale(0) translateY(20px);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.flag-overlay.left .flag-icon {
  color: #ff3366; /* Neon red */
  filter: drop-shadow(0 0 5px rgba(255, 51, 102, 0.8))
         drop-shadow(0 0 10px rgba(255, 51, 102, 0.4))
         drop-shadow(0 0 15px rgba(255, 51, 102, 0.2));
  stroke: #ff3366;
}

.flag-overlay.right .flag-icon {
  color: #33ff66; /* Neon green */
  filter: drop-shadow(0 0 5px rgba(51, 255, 102, 0.8))
         drop-shadow(0 0 10px rgba(51, 255, 102, 0.4))
         drop-shadow(0 0 15px rgba(51, 255, 102, 0.2));
  stroke: #33ff66;
}

.flag-overlay.visible {
  opacity: 1;
}

.flag-overlay.visible .flag-icon {
  transform: scale(1) translateY(0);
}

/* Add a glow animation for the visible flags */
@keyframes neonPulse {
  0% { filter: drop-shadow(0 0 5px currentColor)
              drop-shadow(0 0 10px currentColor)
              drop-shadow(0 0 15px currentColor); }
  50% { filter: drop-shadow(0 0 8px currentColor)
               drop-shadow(0 0 15px currentColor)
               drop-shadow(0 0 20px currentColor); }
  100% { filter: drop-shadow(0 0 5px currentColor)
                drop-shadow(0 0 10px currentColor)
                drop-shadow(0 0 15px currentColor); }
}

.flag-overlay.visible .flag-icon {
  animation: neonPulse 2s ease-in-out infinite;
}

.drag-hint {
  position: absolute;
  bottom: 0.4vw;
  left: 0;
  right: 0;
  text-align: center;
  color: rgba(108, 116, 255, 0.6);
  font-size: min(0.8vw, 14px);
  font-family: 'Share Tech Mono', monospace;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Hover effects */
.scenario-card:hover {
  box-shadow: 
    0 0 30px rgba(13, 13, 15, 0.7),
    0 0 50px rgba(87, 97, 255, 0.1),
    inset 0 0 15px rgba(87, 97, 255, 0.2);
  border-image: linear-gradient(
    135deg,
    rgba(87, 97, 255, 0.6) 0%,
    rgba(87, 97, 255, 0.2) 50%,
    rgba(255, 49, 97, 0.6) 100%
  ) 1;
  transform: translateY(-5px);
}

.scenario-card:hover .data-stream {
  opacity: 1;
}

.scenario-card:hover .card-background-pattern {
  opacity: 0.05;
}

.scenario-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    transparent 50%,
    rgba(87, 97, 255, 0.025) 50%
  );
  background-size: 100% 4px;
  pointer-events: none;
  opacity: 0.5;
}

/* Add hexagon pattern overlay */
.card-background-pattern {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.03;
  background-image: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M30 0l25.98 15v30L30 60 4.02 45V15z' fill-rule='evenodd' stroke='%235761ff' fill='none'/%3E%3C/svg%3E");
  background-size: 30px 30px;
  pointer-events: none;
}

/* Add circuit-like details */
.circuit-lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.circuit-lines::before {
  content: '';
  position: absolute;
  top: 20%;
  left: -10px;
  width: 20px;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(87, 97, 255, 0.3));
}

.circuit-lines::after {
  content: '';
  position: absolute;
  bottom: 30%;
  right: -10px;
  width: 20px;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 49, 97, 0.3), transparent);
}

/* Add data stream effect */
.data-stream {
  position: absolute;
  top: 0;
  right: 10%;
  width: 1px;
  height: 100%;
  background: linear-gradient(
    to bottom,
    transparent,
    rgba(87, 97, 255, 0.2),
    transparent
  );
  animation: dataStream 2s infinite;
}

/* Add animations */
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes dataStream {
  0% { transform: translateY(-100%); opacity: 0; }
  50% { opacity: 1; }
  100% { transform: translateY(100%); opacity: 0; }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 0.5; }
  50% { transform: scale(1.2); opacity: 0.2; }
  100% { transform: scale(1); opacity: 0.5; }
}

/* Add to existing styles */
.scenario-card.disabled {
  opacity: 0.7;
  cursor: not-allowed;
  filter: grayscale(0.3);
}

.scenario-card.disabled:hover {
  transform: none;
  box-shadow: none;
  border-color: rgba(108, 116, 255, 0.2);
}

.scenario-card.disabled .drag-hint {
  color: #ff3366;
}

/* Add media queries for different screen sizes */
@media screen and (min-width: 1440px) {
  .scenario-card {
    width: 300px;
    height: 400px;
  }

  .card-info {
    font-size: 15px;
    padding: 14px;
  }
}

@media screen and (max-width: 1439px) {
  .scenario-card {
    width: min(calc(14vw), 240px);
    height: min(calc(24vw), 380px);
  }
  
  .card-info {
    font-size: 14px;
    padding: 14px;
  }
}

@media screen and (max-width: 768px) {
  .scenario-card {
    width: 240px;
    height: 380px;
  }

  .card-title {
    font-size: 15px;
  }

  .card-info {
    font-size: 14px;
    padding: 14px;
    line-height: 1.6;
  }
}

/* For 2560px (2K/4K) screens */
@media screen and (min-width: 2560px) {
  .scenario-card {
    width: 400px;  /* Larger card width */
    height: 640px; /* Larger card height */
  }

  .card-title {
    font-size: 24px;
    margin: 20px 0;
  }

  .card-info {
    font-size: 20px;
    padding: 24px;
  }

  .card-number {
    width: 50px;
    height: 50px;
    font-size: 24px;
  }

  .drag-hint {
    font-size: 16px;
    bottom: 16px;
  }

  /* Adjust flag overlays for larger cards */
  .flag-overlay {
    top: -60px;
  }

  .flag-overlay .flag-icon {
    width: 40px;
    height: 40px;
  }
}

/* Keep the 1440p styles as they are */
@media screen and (min-width: 1440px) and (max-width: 2559px) {
  .scenario-card {
    width: 300px;
    height: 400px;
  }

  .card-info {
    font-size: 15px;
    padding: 14px;
  }
}

/* Add new media query for screens below 1680px */
@media screen and (max-width: 1680px) {
  .scenario-card {
    width: min(calc(14vw), 260px);
    height: min(calc(24vw), 400px);
  }
  
  .card-title {
    font-size: 16px;
    height: 54px;
  }
  
  .card-info {
    font-size: 15px;
    line-height: 1.6;
    padding: 16px;
  }
  
  .drag-hint {
    font-size: 13px;
    bottom: 12px;
  }
}

/* Update media queries for title */
@media screen and (max-width: 1680px) {
  .card-title {
    font-size: 13px;
    height: 54px;
  }
}

@media screen and (max-width: 1439px) {
  .card-title {
    font-size: 12px;
    height: 48px;
  }
}

@media screen and (max-width: 768px) {
  .card-title {
    font-size: 12px;
    height: 48px;
  }
}

/* Add these new styles */
.flag-hint-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  pointer-events: none;
  z-index: 2;
}

.flag-hint-overlay.red {
  background: rgba(255, 51, 102, 0.05);
}

.flag-hint-overlay.green {
  background: rgba(51, 255, 102, 0.05);
}

.flag-hint-icon {
  width: 48px;
  height: 48px;
  animation: flagPulse 2s ease-in-out infinite;
}

.flag-hint-overlay.red .flag-hint-icon {
  color: rgba(255, 51, 102, 0.3);
  filter: drop-shadow(0 0 10px rgba(255, 51, 102, 0.2));
}

.flag-hint-overlay.green .flag-hint-icon {
  color: rgba(51, 255, 102, 0.3);
  filter: drop-shadow(0 0 10px rgba(51, 255, 102, 0.2));
}

.red-flag-hint {
  box-shadow: 
    0 0 30px rgba(20, 20, 30, 0.5),
    inset 0 0 15px rgba(255, 51, 102, 0.1);
}

.green-flag-hint {
  box-shadow: 
    0 0 30px rgba(20, 20, 30, 0.5),
    inset 0 0 15px rgba(51, 255, 102, 0.1);
}

.red-flag-hint:hover {
  box-shadow: 
    0 0 30px rgba(13, 13, 15, 0.7),
    0 0 50px rgba(255, 51, 102, 0.1),
    inset 0 0 15px rgba(255, 51, 102, 0.2);
}

.green-flag-hint:hover {
  box-shadow: 
    0 0 30px rgba(13, 13, 15, 0.7),
    0 0 50px rgba(51, 255, 102, 0.1),
    inset 0 0 15px rgba(51, 255, 102, 0.2);
}

/* Add to existing styles */
.importance-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  background: rgba(255, 215, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  animation: importancePulse 2s ease-in-out infinite;
}

.importance-icon {
  color: #ffd700;
  font-weight: bold;
  font-size: 16px;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.importance-hint {
  box-shadow: 
    0 0 30px rgba(20, 20, 30, 0.5),
    inset 0 0 15px rgba(255, 215, 0, 0.1);
}

@keyframes importancePulse {
  0% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.1); opacity: 1; }
  100% { transform: scale(1); opacity: 0.8; }
}