.game-intro-modal {
  position: fixed;
  inset: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Space Grotesk', sans-serif;
}

.game-intro-modal__content {
  width: min(600px, 90vw);
  background: linear-gradient(to bottom right, #1c1c2a, #141420);
  border: 1px solid rgba(255, 51, 102, 0.3);
  border-radius: 16px;
  padding: 32px;
  position: relative;
  animation: modalAppear 0.3s ease-out;
}

.game-intro-modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.1);
  transition: all 0.2s;
}

.game-intro-modal__close:hover {
  color: white;
  background: rgba(255, 255, 255, 0.2);
}

.game-intro-modal__step {
  text-align: center;
  animation: stepAppear 0.3s ease-out;
}

.game-intro-modal__image-container {
  width: 200px;
  height: 200px;
  margin: 0 auto 24px;
  border-radius: 12px;
  overflow: hidden;
  border: 2px solid rgba(255, 51, 102, 0.3);
}

.game-intro-modal__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.game-intro-modal__title {
  font-size: 24px;
  font-weight: 700;
  color: white;
  margin-bottom: 16px;
}

.game-intro-modal__text {
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  white-space: pre-line;
  text-align: left;
  margin-bottom: 32px;
}

.game-intro-modal__text span {
  display: inline;
}

.game-intro-modal__button {
  background: #ff3366;
  color: white;
  padding: 12px 24px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  transition: all 0.2s;
  animation: buttonAppear 0.3s ease-out;
}

.game-intro-modal__button:hover {
  background: #ff1f57;
  transform: translateY(-2px);
}

.game-intro-modal__progress {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}

.game-intro-modal__progress-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.2s;
}

.game-intro-modal__progress-dot.active {
  background: #ff3366;
  transform: scale(1.2);
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes stepAppear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes buttonAppear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.flag-instructions {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.flag-option {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.flag-icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.flag-icon.red {
  color: #ff3366;
}

.flag-icon.green {
  color: #4ade80;
} 