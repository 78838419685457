.start-screen {
  position: fixed;
  inset: 0;
  background: linear-gradient(135deg, 
    rgba(31, 31, 48, 0.95) 0%,
    rgba(41, 41, 62, 0.95) 100%
  );
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Share Tech Mono', monospace;
  overflow: hidden;
}

.start-screen::before {
  content: '';
  position: absolute;
  inset: 0;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1) 0px,
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 2px
  );
  pointer-events: none;
}

.start-screen__content {
  width: min(90vw, 1200px);
  height: min(90vh, 800px);
  background: rgba(20, 20, 32, 0.95);
  border: 1px solid rgba(255, 51, 102, 0.3);
  border-radius: 12px;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 30px;
  position: relative;
  box-shadow: 
    0 0 50px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(255, 51, 102, 0.1);
  overflow: hidden;
}

.start-screen__header {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  padding: 20px;
  border-right: 1px solid rgba(255, 51, 102, 0.2);
  position: relative;
  overflow: hidden;
}

.start-screen__header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    repeating-linear-gradient(
      0deg,
      transparent 0px,
      rgba(255, 51, 102, 0.03) 1px,
      transparent 2px
    );
  pointer-events: none;
  animation: static 0.5s steps(4) infinite;
  opacity: 0.2;
}

.start-screen__avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 2px solid rgba(255, 51, 102, 0.5);
  position: relative;
  animation: glitch 2s infinite;
}

.start-screen__title {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: #ff3366;
  text-shadow: 
    0 0 10px rgba(255, 51, 102, 0.4),
    0 0 20px rgba(255, 51, 102, 0.2);
  letter-spacing: 4px;
  animation: glitch 3s infinite;
}

.start-screen__subtitle,
.start-screen__warning {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
  letter-spacing: 2px;
  animation: glitch 2s infinite;
}

.start-screen__subtitle {
  color: #8f96ff;
  animation-delay: 0.3s;
}

.start-screen__warning {
  color: #ff3366;
  animation-delay: 0.7s;
}

.start-screen__story {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.start-screen__message {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 15px;
  border-radius: 8px;
  font-size: 0.95rem;
  line-height: 1.6;
  animation: messageAppear 0.3s ease forwards;
}

.start-screen__message.system {
  background: rgba(108, 116, 255, 0.1);
  border: 1px solid rgba(108, 116, 255, 0.3);
  color: #8f96ff;
}

.start-screen__message.evil {
  background: rgba(255, 51, 102, 0.1);
  border: 1px solid rgba(255, 51, 102, 0.3);
  color: #ff3366;
}

.start-screen__message.warning {
  background: rgba(255, 204, 0, 0.1);
  border: 1px solid rgba(255, 204, 0, 0.3);
  color: #ffcc00;
}

.start-screen__message ul {
  margin: 10px 0 0 20px;
  padding: 0;
}

.start-screen__button {
  align-self: center;
  background: none;
  border: 1px solid #ff3366;
  color: #ff3366;
  padding: 15px 30px;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 6px;
}

.start-screen__button:hover {
  background: rgba(255, 51, 102, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(255, 51, 102, 0.2);
}

.start-screen__footer {
  text-align: center;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ff3366;
  font-size: 0.9rem;
}

.highlight {
  color: inherit;
  font-weight: bold;
  text-shadow: 0 0 10px currentColor;
}

@keyframes messageAppear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotateAvatar {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.start-screen__terminal {
  flex: 1;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 51, 102, 0.2);
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
  font-family: 'Share Tech Mono', monospace;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
}

.start-screen__terminal-message {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  border-radius: 4px;
  animation: startScreenFadeIn 0.5s ease-out;
  white-space: pre-line;
  text-align: left;
  border: 1px solid transparent;
}

.start-screen__terminal-message--system {
  color: #8f96ff;
  background: rgba(108, 116, 255, 0.05);
  border-color: rgba(108, 116, 255, 0.2);
}

.start-screen__terminal-message--evil {
  color: #ff3366;
  background: rgba(255, 51, 102, 0.05);
  border-color: rgba(255, 51, 102, 0.2);
}

.start-screen__terminal-message--warning {
  color: #ffcc00;
  background: rgba(255, 204, 0, 0.05);
  border-color: rgba(255, 204, 0, 0.2);
}

.start-screen__terminal-message--error {
  color: #ff3366;
  background: rgba(255, 51, 102, 0.05);
  border-color: rgba(255, 51, 102, 0.2);
}

.start-screen__message-icon {
  flex-shrink: 0;
  margin-top: 3px;
}

.start-screen__message-content {
  animation: startScreenTypeWriter 0.5s steps(30, end);
  flex: 1;
}

.start-screen__command-input {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #33ff66;
  padding: 12px;
  background: rgba(51, 255, 102, 0.05);
  border: 1px solid rgba(51, 255, 102, 0.2);
  border-radius: 4px;
  animation: startScreenFadeIn 0.3s ease-out;
}

.start-screen__command-prompt {
  color: #33ff66;
  font-weight: bold;
}

.start-screen__command-field {
  background: none;
  border: none;
  color: #33ff66;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1rem;
  width: 100%;
  outline: none;
  caret-color: #33ff66;
  border-right: none;
}

@keyframes startScreenFadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes startScreenTypeWriter {
  from { clip-path: inset(0 100% 0 0); }
  to { clip-path: inset(0 0 0 0); }
}

@keyframes glitch {
  0% {
    transform: translate(0);
    filter: hue-rotate(0deg);
  }
  1% {
    transform: translate(-2px, 2px);
    filter: hue-rotate(90deg);
  }
  2% {
    transform: translate(2px, -2px);
    filter: hue-rotate(180deg);
  }
  3% {
    transform: translate(0);
    filter: hue-rotate(0deg);
  }
  20% {
    transform: translate(0);
    filter: hue-rotate(0deg);
  }
  21% {
    transform: translate(3px, 3px);
    filter: hue-rotate(-90deg);
  }
  22% {
    transform: translate(-3px, -3px);
    filter: hue-rotate(90deg);
  }
  23% {
    transform: translate(0);
    filter: hue-rotate(0deg);
  }
  100% {
    transform: translate(0);
    filter: hue-rotate(0deg);
  }
}

.start-screen__avatar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    repeating-radial-gradient(
      circle at 50%,
      transparent 0,
      rgba(255, 51, 102, 0.1) 1px,
      transparent 2px
    );
  border-radius: 50%;
  animation: static 0.5s steps(4) infinite;
  opacity: 0.5;
  pointer-events: none;
}

@keyframes static {
  0% { transform: translate(0); }
  25% { transform: translate(-1px, 1px); }
  50% { transform: translate(1px, -1px); }
  75% { transform: translate(-1px, -1px); }
  100% { transform: translate(1px, 1px); }
}

.start-screen__icon,
.start-screen__warning-icon {
  animation: iconGlitch 1.5s infinite;
}

.start-screen__warning-icon {
  animation-delay: 0.5s;
}

@keyframes iconGlitch {
  0% {
    transform: translate(0);
    opacity: 1;
  }
  1% {
    transform: translate(-2px, 2px);
    opacity: 0.8;
  }
  2% {
    transform: translate(2px, -2px);
    opacity: 0.9;
  }
  3% {
    transform: translate(0);
    opacity: 1;
  }
  20% {
    transform: translate(0);
    opacity: 1;
  }
  21% {
    transform: translate(3px, 3px);
    opacity: 0.7;
  }
  22% {
    transform: translate(-3px, -3px);
    opacity: 0.8;
  }
  23% {
    transform: translate(0);
    opacity: 1;
  }
  100% {
    transform: translate(0);
    opacity: 1;
  }
}

.start-screen__loading {
  padding: 12px;
  background: rgba(51, 255, 102, 0.05);
  border: 1px solid rgba(51, 255, 102, 0.2);
  border-radius: 4px;
  margin-top: 12px;
}

.start-screen__loading-bar {
  height: 20px;
  background: rgba(51, 255, 102, 0.1);
  border: 1px solid rgba(51, 255, 102, 0.3);
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 8px;
}

.start-screen__loading-progress {
  height: 100%;
  background: #33ff66;
  transition: width 0.2s ease-out;
  box-shadow: 0 0 10px rgba(51, 255, 102, 0.4);
}

.start-screen__loading-text {
  color: #33ff66;
  font-family: 'Share Tech Mono', monospace;
  font-size: 14px;
  text-align: center;
}

.start-screen__mute-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: none;
  border: none;
  color: #8f96ff;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(143, 150, 255, 0.3);
}

.start-screen__mute-button:hover {
  color: #ff3366;
  border-color: rgba(255, 51, 102, 0.3);
  transform: scale(1.1);
}

.start-screen__mute-button svg {
  animation: iconGlitch 2s infinite;
} 