.crew-messages {
  position: absolute;
  inset: 0;
  pointer-events: none;
  z-index: 1000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.crew-messages__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 80%;
  padding: 20px;
}

.crew-message {
  position: relative;
  opacity: 0;
  animation: messageAppear 0.5s ease-out forwards;
}

.crew-message__content {
  display: inline-block;
  padding: 12px 30px;
  font-family: 'Rubik Distressed', cursive;
  font-size: 1rem;
  color: #1b3b50;
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 1;
  clip-path: polygon(
    0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
    80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
    78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
    3% 100%, 0 85%
  );
  text-shadow: 
    -1px -1px 0 rgba(255, 255, 255, 0.7),
    1px -1px 0 rgba(255, 255, 255, 0.7),
    -1px 1px 0 rgba(255, 255, 255, 0.7),
    1px 1px 0 rgba(255, 255, 255, 0.7);
}

.crew-message__frost {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  clip-path: polygon(
    0 15%, 3% 0, 20% 0, 22% 2%, 40% 2%, 42% 0, 60% 0, 61% 2%,
    80% 2%, 82% 0, 97% 0, 100% 15%, 100% 85%, 97% 100%, 80% 100%,
    78% 98%, 60% 98%, 58% 100%, 40% 100%, 38% 98%, 20% 98%, 18% 100%,
    3% 100%, 0 85%
  );
  z-index: 2;
  pointer-events: none;
}

@keyframes messageAppear {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .crew-messages__container {
    max-width: 90%;
  }

  .crew-message__content {
    font-size: 0.8rem;
    padding: 8px 20px;
  }
} 