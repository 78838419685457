.simulation-editor {
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-width: 1024px;
  overflow: auto;
}

.editor-main {
  display: flex;
  flex: 1;
  overflow: hidden;
  min-width: 0;
}