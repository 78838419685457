.antarctica-start-screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    #1b3b50 0%,
    #2c5474 50%,
    #1b3b50 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10vh 0 10vh;
  z-index: 1000;
  overflow: hidden;
  opacity: 1;
  transition: opacity 4s ease-out;
}

.antarctica-start-screen.fade-out {
  opacity: 0;
  pointer-events: none;
}

.antarctica-start-screen::before {
  content: '';
  position: absolute;
  inset: 0;
  background: 
    radial-gradient(
      circle at 30% 20%,
      rgba(147, 186, 217, 0.2) 0%,
      transparent 60%
    ),
    radial-gradient(
      circle at 70% 80%,
      rgba(147, 186, 217, 0.15) 0%,
      transparent 60%
    );
  pointer-events: none;
}

.antarctica-start-screen__title {
  font-family: 'Rubik Distressed', cursive;
  font-size: 5rem;
  color: #ffffff;
  text-shadow: 
    0 0 20px rgba(255, 255, 255, 0.3),
    0 0 40px rgba(147, 186, 217, 0.3);
  margin: 0;
}

.start-button {
  transform: scale(1.2);
}

.start-button:hover {
  transform: scale(1.2) translateY(-2px);
}

.ice-button--disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none !important;
}

.ice-button--disabled:hover {
  transform: none !important;
}

/* Loading Screen Styles */
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  color: #ffffff;
  text-align: center;
  padding: 20vh 20px;
}

.loading-screen__progress {
  width: 300px;
  height: 4px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  overflow: hidden;
  margin-bottom: 40px;
}

.loading-screen__progress-bar {
  height: 100%;
  width: 0%;
  background: linear-gradient(
    90deg,
    rgba(147, 186, 217, 0.5),
    rgba(255, 255, 255, 0.8)
  );
  animation: progress 5s ease-in-out forwards;
  box-shadow: 0 0 10px rgba(147, 186, 217, 0.5);
}

.loading-screen__messages {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;
}

.loading-screen__message {
  font-family: 'Crimson Text', serif;
  font-size: 1.5rem;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  text-shadow: 0 0 10px rgba(147, 186, 217, 0.5);
  line-height: 1.4;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

/* Add frost effect during loading */
.loading-screen::after {
  content: '';
  position: absolute;
  inset: 0;
  background: 
    radial-gradient(
      circle at 30% 20%,
      rgba(255, 255, 255, 0.1) 0%,
      transparent 60%
    ),
    radial-gradient(
      circle at 70% 80%,
      rgba(255, 255, 255, 0.08) 0%,
      transparent 60%
    );
  pointer-events: none;
  animation: frostShimmer 10s infinite linear;
}

@keyframes frostShimmer {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
} 