.simulation-view {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #141420;
}

.simulation-content {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
} 