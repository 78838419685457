.news-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(20, 20, 32, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  backdrop-filter: blur(8px);
}

.news-alert {
  background: #1f1f30;
  border-radius: 12px;
  padding: 30px;
  width: 90%;
  max-width: 500px;
  position: relative;
  border: 1px solid rgba(108, 116, 255, 0.3);
  box-shadow: 
    0 0 50px rgba(0, 0, 0, 0.5),
    0 0 100px rgba(108, 116, 255, 0.1);
  animation: newsSlideIn 0.3s ease-out;
}

.news-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.news-icon {
  width: 32px;
  height: 32px;
}

.news-icon.positive {
  color: #33ff66;
  filter: drop-shadow(0 0 10px rgba(51, 255, 102, 0.5));
}

.news-icon.negative {
  color: #ff3366;
  filter: drop-shadow(0 0 10px rgba(255, 51, 102, 0.5));
}

.news-icon.header-icon {
  color: #8f96ff;
  filter: drop-shadow(0 0 10px rgba(108, 116, 255, 0.5));
}

.news-title {
  color: #8f96ff;
  font-family: 'Orbitron', sans-serif;
  font-size: 1.5rem;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 0 0 10px rgba(108, 116, 255, 0.5);
}

.news-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
}

.news-amount {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Share Tech Mono', monospace;
  font-size: 2rem;
}

.news-amount svg {
  color: #8f96ff;
}

.news-amount span.positive {
  color: #33ff66;
  text-shadow: 0 0 10px rgba(51, 255, 102, 0.5);
}

.news-amount span.negative {
  color: #ff3366;
  text-shadow: 0 0 10px rgba(255, 51, 102, 0.5);
}

.news-description {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
}

.news-impact-description {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 0.9rem;
  font-style: italic;
}

.news-button {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(135deg, #4A90E2, #5761ff);
  color: white;
  font-family: 'Share Tech Mono', monospace;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.news-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 15px rgba(108, 116, 255, 0.3);
}

@keyframes newsSlideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.news-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.news-headline {
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.4;
  margin: 0;
  font-family: 'Share Tech Mono', monospace;
}

.news-subheadline {
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.4;
  margin: 0;
  font-family: 'Share Tech Mono', monospace;
} 