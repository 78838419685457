.rogue-ai-lock {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(20, 20, 32, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    backdrop-filter: blur(8px);
  }
  
  .rogue-ai-container {
    background: #1f1f30;
    border-radius: 12px;
    padding: 30px;
    max-width: 600px;
    width: 90%;
    border: 1px solid rgba(255, 51, 102, 0.3);
    box-shadow: 
      0 0 50px rgba(255, 51, 102, 0.1),
      0 0 100px rgba(255, 51, 102, 0.05);
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  
  .rogue-ai-icon {
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .omnius-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid rgba(255, 51, 102, 0.5);
    box-shadow: 
      0 0 20px rgba(255, 51, 102, 0.3),
      0 0 40px rgba(255, 51, 102, 0.2),
      inset 0 0 10px rgba(255, 51, 102, 0.5);
    animation: pulseAvatar 2s infinite;
  }
  
  .rogue-ai-icon::before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
    background: conic-gradient(
      from 0deg,
      transparent,
      rgba(255, 51, 102, 0.8) 45deg,
      rgba(255, 51, 102, 0.3) 180deg,
      transparent 360deg
    );
    animation: rotate 4s linear infinite;
    z-index: -1;
  }
  
  .rogue-ai-icon::after {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 50%;
    background: radial-gradient(circle at center,
      rgba(255, 51, 102, 0.3) 0%,
      transparent 70%
    );
    filter: blur(8px);
    animation: pulseGlow 3s infinite;
  }
  
  @keyframes pulseAvatar {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes pulseGlow {
    0%, 100% {
      opacity: 0.5;
      transform: scale(1);
    }
    50% {
      opacity: 0.8;
      transform: scale(1.1);
    }
  }
  
  .rogue-ai-message {
    color: #ff3366;
    font-family: 'Share Tech Mono', monospace;
    text-align: center;
    line-height: 1.6;
    font-size: 1.1rem;
  }
  
  .message-typing-effect {
    border-right: 2px solid #ff3366;
    white-space: pre-wrap;
    animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
  }
  
  .passcode-form {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
  }
  
  .passcode-input {
    flex: 1;
    background: rgba(35, 35, 54, 0.8);
    border: 1px solid rgba(255, 51, 102, 0.3);
    padding: 12px;
    border-radius: 6px;
    color: #fff;
    font-family: 'Share Tech Mono', monospace;
    font-size: 1rem;
  }
  
  .passcode-input:focus {
    outline: none;
    border-color: rgba(255, 51, 102, 0.6);
    box-shadow: 0 0 10px rgba(255, 51, 102, 0.2);
  }
  
  .passcode-submit {
    background: linear-gradient(135deg, #ff3366, #ff5151);
    border: none;
    border-radius: 6px;
    width: 50px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  
  .passcode-submit:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 15px rgba(255, 51, 102, 0.3);
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #ff3366 }
  }
  
  .passcode-error {
    color: #ff3366;
    font-family: 'Share Tech Mono', monospace;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
    padding: 8px;
    background: rgba(255, 51, 102, 0.1);
    border-radius: 4px;
    border: 1px solid rgba(255, 51, 102, 0.3);
    animation: fadeIn 0.3s ease;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }